import { Col, Divider, Empty, Row } from "antd";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import ReviewCard from "../../component/rent/ReviewCard";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Banner from "../../assets/images/home/ruta40/banner_review.png";

const BOARD_ID = Common.BEST_REVIEW_BOARD_ID + "-" + Common.USER_REVIEW_BOARD_ID;

function BestReview() {
    const [allReviews, setAllReviews] = useState<TypeDTO.BoardArticleDto[]>();
    const [bestReviewsMonth, setBestReviewsMonth] = useState<TypeDTO.BoardArticleDto[]>([]);
    const [bestReviews, setBestReviews] = useState<TypeDTO.BoardArticleDto[]>([]);
    const [userReviews, setUserReviews] = useState<TypeDTO.BoardArticleDto[]>([]);
    const [requestReviewsPage, setRequestReviewsPage] = useState(0);
    const [tempReviews, setTempReviews] = useState<TypeDTO.BoardArticleDto[]>();

    useEffect(() => {
        requestGetBoardArticles();
    }, []);

    const requestAxiosGetBoardArticles = async () => {
        const response = await utilAxiosWithAppToken().get(Request.BOARD_URL + "/" + BOARD_ID, {
            params: { page: requestReviewsPage, size: 0 },
        });
        return response.data;
    };

    const {
        loading: loadingGetBoardArticles,
        error: errorGetBoardArticles,
        data: resultGetBoardArticles,
        execute: requestGetBoardArticles,
    } = useAsyncAxios(requestAxiosGetBoardArticles);

    useEffect(() => {
        if (!resultGetBoardArticles) return;
        console.log("resultGetBoardArticles", resultGetBoardArticles);

        if (resultGetBoardArticles.boardArticles.totalPages > requestReviewsPage + 1) {
            setTempReviews([...(tempReviews || []), ...resultGetBoardArticles.boardArticles.content]);
            setRequestReviewsPage(requestReviewsPage + 1);
        } else {
            const allReviews = [...(tempReviews || []), ...resultGetBoardArticles.boardArticles.content];
            setAllReviews(allReviews);
            setBestReviewsMonth(allReviews?.filter((review: TypeDTO.BoardArticleDto) => review.fixed));
            setBestReviews(
                allReviews?.filter((review: TypeDTO.BoardArticleDto) => !review.fixed && review.board.boardId === Common.BEST_REVIEW_BOARD_ID)
            );
            setUserReviews(
                allReviews?.filter((review: TypeDTO.BoardArticleDto) => !review.fixed && review.board.boardId === Common.USER_REVIEW_BOARD_ID)
            );
            setRequestReviewsPage(0);
            setTempReviews([]);
        }
    }, [resultGetBoardArticles]);

    useEffect(() => {
        if (requestReviewsPage === 0) return;
        requestGetBoardArticles();
    }, [requestReviewsPage]);

    useEffect(() => {
        if (!errorGetBoardArticles) return;
        console.log("errorGetBoardArticles", errorGetBoardArticles);
    }, [errorGetBoardArticles]);

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div className="container fadeIn text-center mb-5" style={{ backgroundImage: `url(${Banner})` }}>
                    <h5 className="text-center px-3 mb-3" style={{ color: "#4d4d4d" }}>
                        이용 후기
                    </h5>
                    <h1>REVIEW</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <MobileView className="mobile">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="fadeInUp p-4"
                    >
                        <section className="row legal">
                            <div className="text-center">
                                <h3>이달의 베스트 리뷰</h3>
                                <Divider className="title-divider mb-5" />
                            </div>
                        </section>
                    </Col>
                    <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                        <div className="simple-slider">
                            {allReviews?.length === 0 && <Empty />}
                            {bestReviewsMonth.length > 1
                                ? bestReviewsMonth.map((boardArticle) => {
                                      return (
                                          <Col sm={{ span: 24 }} lg={{ span: 8 }} key={boardArticle.boardArticleId} className={`mb-2`}>
                                              <ReviewCard boardArticle={boardArticle} className="best" />
                                          </Col>
                                      );
                                  })
                                : bestReviewsMonth.length === 1 && (
                                      <Col
                                          sm={{ span: 24 }}
                                          xl={{ span: 6 }}
                                          lg={{ span: 8 }}
                                          key={bestReviewsMonth[0].boardArticleId}
                                          className={`mb-2 w-100`}
                                      >
                                          <ReviewCard boardArticle={bestReviewsMonth[0]} />
                                      </Col>
                                  )}
                        </div>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 22, offset: 1 }}
                            lg={{ span: 18, offset: 3 }}
                            xl={{ span: 16, offset: 4 }}
                            xxl={{ span: 14, offset: 5 }}
                            className="fadeInUp p-4"
                        >
                            {bestReviews.length > 0 && (
                                <>
                                    <Divider style={{ marginTop: 16, marginBottom: 0, paddingBottom: 40 }} />
                                    <div className="text-center mb-3">
                                        <h3>베스트 리뷰</h3>
                                        <Divider className="title-divider mb-5" />
                                    </div>
                                    <div className="review-list">
                                        {bestReviews.map((boardArticle) => {
                                            return (
                                                <div key={boardArticle.boardArticleId}>
                                                    <ReviewCard boardArticle={boardArticle} small={true} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 22, offset: 1 }}
                            lg={{ span: 18, offset: 3 }}
                            xl={{ span: 16, offset: 4 }}
                            xxl={{ span: 14, offset: 5 }}
                            className="fadeInUp p-4"
                        >
                            {userReviews.length > 0 && (
                                <>
                                    <Divider style={{ marginTop: 16, marginBottom: 0, paddingBottom: 40 }} />
                                    <div className="text-center mb-3">
                                        <h3>리뷰</h3>
                                        <Divider className="title-divider mb-5" />
                                    </div>
                                    <div className="review-list">
                                        {userReviews.map((boardArticle) => {
                                            return (
                                                <div key={boardArticle.boardArticleId}>
                                                    <ReviewCard boardArticle={boardArticle} small={true} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </Col>
                    </div>
                </MobileView>
                <BrowserView className="browser">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className={`fadeIn p-4`}
                    >
                        <section className="row legal">
                            <div className="text-center my-3">
                                <h3>이달의 베스트 리뷰</h3>
                                <Divider className="title-divider mb-5" />
                            </div>
                        </section>
                    </Col>
                    <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 22, offset: 1 }}
                            lg={{ span: 18, offset: 3 }}
                            xl={{ span: 16, offset: 4 }}
                            xxl={{ span: 14, offset: 5 }}
                            className="fadeInUp p-4"
                        >
                            <Row gutter={24} style={{ justifyContent: "center" }}>
                                {allReviews?.length === 0 && <Empty />}
                                {bestReviewsMonth.map((boardArticle) => {
                                    return (
                                        <Col sm={{ span: 24 }} lg={{ span: 8 }} key={boardArticle.boardArticleId} className={`mb-5 w-100`}>
                                            <ReviewCard boardArticle={boardArticle} className="best" />
                                        </Col>
                                    );
                                })}
                            </Row>

                            {bestReviews.length > 0 && (
                                <>
                                    <Divider style={{ marginTop: 0, marginBottom: 48 }} />
                                    <div className="text-center mb-5 mt-3">
                                        <h3>베스트 리뷰</h3>
                                    </div>
                                    <Row gutter={24}>
                                        {bestReviews.map((boardArticle) => {
                                            return (
                                                <Col
                                                    sm={{ span: 24 }}
                                                    xl={{ span: 6 }}
                                                    lg={{ span: 8 }}
                                                    key={boardArticle.boardArticleId}
                                                    className={`mb-5 w-100`}
                                                >
                                                    <ReviewCard boardArticle={boardArticle} />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </>
                            )}

                            {userReviews.length > 0 && (
                                <>
                                    <Divider style={{ marginTop: 0, marginBottom: 48 }} />
                                    <div className="text-center mb-5 mt-3">
                                        <h3>리뷰</h3>
                                    </div>
                                    <Row gutter={24}>
                                        {userReviews.map((boardArticle) => {
                                            return (
                                                <Col
                                                    sm={{ span: 24 }}
                                                    xl={{ span: 6 }}
                                                    lg={{ span: 8 }}
                                                    key={boardArticle.boardArticleId}
                                                    className={`mb-5 w-100`}
                                                >
                                                    <ReviewCard boardArticle={boardArticle} />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </>
                            )}
                        </Col>
                    </div>
                </BrowserView>
            </div>
        </div>
    );
}

export default BestReview;
