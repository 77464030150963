import React from "react";
import { Col, Row, Space, Image } from "antd";
import { useMediaQuery } from "react-responsive";

import droneStationMain3 from "../assets/images/model/droneStation/droneStationMain3.jpg";
import droneStationMain4 from "../assets/images/model/droneStation/droneStationMain4.jpg";
import droneStationExterior10 from "../assets/images/model/droneStation/droneStationExterior10.jpg";
import droneStationExterior11 from "../assets/images/model/droneStation/droneStationExterior11.jpg";
import droneStationExterior12 from "../assets/images/model/droneStation/droneStationExterior12.jpg";
import droneStationExterior13 from "../assets/images/model/droneStation/droneStationExterior13.jpg";
import droneStationExterior14 from "../assets/images/model/droneStation/droneStationExterior14.jpg";
import droneStationExterior15 from "../assets/images/model/droneStation/droneStationExterior15.jpg";
import droneStationExterior16 from "../assets/images/model/droneStation/droneStationExterior16.jpg";
import droneStationExterior17 from "../assets/images/model/droneStation/droneStationExterior17.jpg";
import droneStationExterior18 from "../assets/images/model/droneStation/droneStationExterior18.jpg";

import droneStationSpec3 from "../assets/images/model/droneStation/droneStationSpec3.svg";
import fmsImage1 from "../assets/images/fms/fms1.jpg";
import fmsImage5 from "../assets/images/fms/fms5.jpg";

export default function ModelDroneStationRT60() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                <div className={`mb-5 mt-3`}>
                    {isMobile ? <img className="w-100" src={droneStationMain4}></img> : <img className="w-100 img-fluid" src={droneStationMain3} />}
                </div>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                <div className="mb-5">
                    <div className="text-center mb-5 mt-3">
                        <h3>디자인</h3>
                    </div>

                    <Row className="row px-2">
                        <Col span={8} className="col-lg-4 px-1">
                            <Space direction="vertical" size={isMobile ? 4 : 8}>
                                <Image src={droneStationExterior13} className="w-100 shadow-1-strong" alt="Wintry Mountain Landscape" />
                                <Image src={droneStationExterior10} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                                <Image src={droneStationExterior16} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                            </Space>
                        </Col>

                        <Col span={8} className="col-lg-4 px-1">
                            <Space direction="vertical" size={isMobile ? 4 : 8}>
                                <Image src={droneStationExterior14} className="w-100 shadow-1-strong" alt="Wintry Mountain Landscape" />
                                <Image src={droneStationExterior11} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                                <Image src={droneStationExterior17} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                            </Space>
                        </Col>

                        <Col span={8} className="col-lg-4 px-1">
                            <Space direction="vertical" size={isMobile ? 4 : 8}>
                                <Image src={droneStationExterior15} className="w-100 shadow-1-strong" alt="Waves at Sea" />
                                <Image src={droneStationExterior12} className="w-100 shadow-1-strong" alt="Boat on Calm Water" />
                                <Image src={droneStationExterior18} className="w-100 shadow-1-strong" alt="Boat on Calm Water" />
                            </Space>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                <div className="mb-5">
                    <div className="text-center mb-5">
                        <h3>서비스</h3>
                    </div>
                    <div className="col-lg-12 text-center pb-4">
                        <h6 className="text-type">차량 관제 시스템</h6>
                        <p style={{ wordBreak: "keep-all" }} className="b-md">
                            정밀한 정비 서비스 제공과 고객 안전을 위한 차량 관제 서비스를 지원합니다.
                        </p>
                        {isMobile ? <img className="w-100" src={fmsImage5}></img> : <img className="w-100" src={fmsImage1}></img>}
                    </div>
                </div>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                <div className="mb-5">
                    <div className="text-center mb-5">
                        <h3>제원 정보</h3>
                    </div>
                    <div className="col-lg-12 text-center optionBox pb-4">
                        <div className="col-md-12 py-3">
                            {isMobile ? (
                                <img className="px-3 d-block w-100" alt="First slide" src={droneStationSpec3} />
                            ) : (
                                <img className="px-5 d-block img-fluid" alt="First slide" src={droneStationSpec3} />
                            )}
                        </div>
                        <div className="col-md-12">
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>제원명</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>치수(mm)</th>
                                        <td>5,625×2,015×2,230</td>
                                    </tr>
                                    <tr>
                                        <th>차량 중량(kg)</th>
                                        <td>2,365</td>
                                    </tr>
                                    <tr>
                                        <th>적재 용량(m²)</th>
                                        <td>8.3</td>
                                    </tr>
                                    <tr>
                                        <th>적재고(mm)</th>
                                        <td>495</td>
                                    </tr>
                                    <tr>
                                        <th>최대 속도(km/h)</th>
                                        <td>120</td>
                                    </tr>
                                    <tr>
                                        <th>최대 주행거리(km)</th>
                                        <td>317</td>
                                    </tr>
                                    <tr>
                                        <th>배터리(KWh)</th>
                                        <td>76.1(Li-Ion)</td>
                                    </tr>
                                    <tr>
                                        <th>모터 PMSM Tyre</th>
                                        <td>215/65R17C</td>
                                    </tr>
                                    <tr>
                                        <th>제동장치</th>
                                        <td>ABS</td>
                                    </tr>
                                    <tr>
                                        <th>스티어링</th>
                                        <td>R_MDPS</td>
                                    </tr>
                                    <tr>
                                        <th>구동방식</th>
                                        <td>앞모터-앞바퀴굴림(FF)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    );
}
