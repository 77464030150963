import React from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "antd";
import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

import instaLogo from "../assets/images/instagram.svg";
import youtubeLogo from "../assets/images/youtube.svg";
import { BrowserView, MobileView } from "react-device-detect";

function EngFooter() {
    const navigate = useNavigate();
    return (
        <>
            <MobileView className="mobile">
                <div className="footer">
                    <div className={"bg-gray"}>
                        <Col sm={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }}>
                            <div className="container">
                                <div className="row">
                                    <div className={"col-lg-3 col-md-6 m-0"}>
                                        <p className={"mt-4 mb-2 company-name"}>RUTAS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="copyright pt-2">
                                    <div className="row">
                                        <div className={"col-md-7 text-md-start mb-3 mb-md-0 no-order"}>
                                            <p>Email : support@rutas.co.kr</p>
                                            <p>Call : +82-70-7707-1768</p>
                                            <p className="TOS-wrapper">
                                                Site : <a onClick={() => Utils.sendLinkToApp("http://rutas.co.kr/")}>RUTAS</a> |{" "}
                                                <a onClick={() => Utils.sendLinkToApp("https://ruta40.co.kr/home")}>RUTA40</a>
                                            </p>
                                        </div>

                                        <MobileView className="footer-menu text-right">
                                            <img
                                                onClick={() => {
                                                    Utils.sendLinkToApp("https://youtube.com/@rutas_ev?si=pBKO0kQvs5YnpuMO");
                                                }}
                                                src={youtubeLogo}
                                            />
                                        </MobileView>
                                    </div>
                                    <div className="row">
                                        <div className={`my-3 text-left text-md-start copyright-section no-order`}>
                                            <p style={{ fontSize: "8px" }}>
                                                &copy;
                                                <a className="footer-anchor" href="http://rutas.co.kr/">
                                                    RUTAS
                                                </a>
                                                , All Right Reserved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </MobileView>
            <BrowserView className="browser">
                <div className="footer">
                    <div className={"bg-gray text-light"}>
                        <Col sm={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }}>
                            <div className="container">
                                <div className="row">
                                    <div className={"col-lg-3 col-md-6 m-0"}>
                                        <p className={"mt-4 mb-2"}>RUTAS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="copyright pt-2">
                                    <div className="row">
                                        <div className={"col-md-7 text-md-start mb-3 mb-md-0"}>
                                            <p>Email : support@rutas.co.kr</p>
                                            <p>Call : +82-70-7707-1768</p>
                                            <p className="TOS-wrapper">
                                                Site : <a onClick={() => Utils.sendLinkToApp("http://rutas.co.kr/")}>RUTAS</a> |{" "}
                                                <a onClick={() => Utils.sendLinkToApp("https://ruta40.co.kr/home")}>RUTA40</a>
                                            </p>
                                        </div>

                                        <div className="col-md-5 text-md-end">
                                            <div className="footer-menu text-right">
                                                <img
                                                    onClick={() => {
                                                        Utils.sendLinkToApp("https://youtube.com/@rutas_ev?si=pBKO0kQvs5YnpuMO");
                                                    }}
                                                    src={youtubeLogo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`my-3 text-left text-md-start copyright-section`}>
                                            <p style={{ fontSize: "8px" }}>
                                                &copy;
                                                <a className="footer-anchor" onClick={() => Utils.sendLinkToApp("http://rutas.co.kr/")}>
                                                    RUTAS
                                                </a>
                                                , All Right Reserved. Designed By{" "}
                                                <a className="footer-anchor" href="http://rutas.co.kr/">
                                                    RUTAS.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </BrowserView>
        </>
    );
}

export default EngFooter;
