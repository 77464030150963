import * as Utils from "../utils/utils";

export const SITE_OPEN_DATE = "2023-08-02T09:00:00";
export const OPEN_DATE = "2023-08-02T09:00:00"; //TODO: 오픈 날짜 바꾸기
export const RESERVATION_OPEN_DATE = "2023-08-11T00:00:00"; //TODO: 오픈 날짜 바꾸기

/*session storage*/
export const CONTEXT_AUTH = "RUTA40-01";
export const CONTEXT_TABOPEN = "RUTA40-02";
export const CONTEXT_SAVEID = "RUTA40-03";
export const CONTEXT_DONOT_SHOW_EVINFO = "RUTA40-04";
export const CONTEXT_LOCAL_TIME_GAP = "RUTA40-05";

export const SESSION_SHOW_MODAL = "showModal";
export const SESSION_SHOW_DRAWER = "showDrawer";
export const SESSION_SHOW_MENU = "showMenu";
export const SESSION_SHOW_PICKER = "showPicker";

/*url name*/
export const PAGE_NOTFOUND = "/notFound";

export const PAGE_LOGIN = "/login";
export const PAGE_SIGNUP = "/signup";
export const PAGE_FIND_USER = "/login/find";
export const PAGE_MYPAGE = "/mypage";
export const PAGE_UPDATE_MEMBER = "/update";
export const PAGE_WITHDRAW_MEMBER = "/withdraw";
export const PAGE_DRIVER_LICENSE = "/license";

export const PAGE_HOME = "/home";
export const PAGE_STORY = "/story";
export const PAGE_OPTION = "/option";
export const PAGE_MEDIA = "/media";
export const PAGE_DIGITALKEY = "/digitalkey";
export const PAGE_FMS = "/fms";
export const PAGE_INFORMATION = "/information";
export const PAGE_INFORMATION_RENT = "/information/rent";
export const PAGE_INFORMATION_USE = "/information/use";
export const PAGE_TRAVEL_SPOT = "/travelSpot";

export const PAGE_FAQ = "/faq";
export const PAGE_DOWNLOADCENTER = "/downloadcenter";
export const PAGE_MY_ORDER = "/my-order";
export const PAGE_MY_ORDER_DETAIL = "/my-order/detail";
export const PAGE_MY_COUPON = "/my-coupon";
export const PAGE_ORDER = "/order";
export const PAGE_PAYMENT = "/payment";
export const PAGE_MODEL = "/model";
export const PAGE_NOTICE = "/notice";
export const PAGE_NOTICE_DETAIL = "/notice/detail";

export const PAGE_TOS = "/tos";
export const PAGE_PRIVACPOLICY = "/privacypolicy";
export const PAGE_USAGE_DETAIL = "/usage/detail";

export const PAGE_DIGITALKEY_CONTROL = "/digitalkey-control";
export const PAGE_FIND_EV_STATION = "/findEVStation";
export const PAGE_MANAGE_CAR = "/manageCar";

export const PAGE_RENT_HOME = "/home";
export const PAGE_RENT_MAP = "/map";
export const PAGE_RENT_DETAIL = "/detail";
export const PAGE_RENT_RESERVATION = "/reservation";
export const PAGE_RENT_RESULT = "/result";
export const PAGE_RENT_MANUAL = "/manual";
export const PAGE_RENT_NOTICE = "/notice";
export const PAGE_RENT_NOTICE_DETAIL = "/notice/detail";
export const PAGE_RENT_BEST_REVIEW = "/rent/best-review";
export const PAGE_RENT_USER_REVIEW = "/rent/user-review";
export const PAGE_RENT_PROMOTION = "/promotion";
export const PAGE_RENT_PROMOTION_DETAIL = "/promotion/detail";

export const PAGE_COUPON_DETAIL = "/coupon/detail";
export const PAGE_RESERVATION_DETAIL = "/reservation/detail";

export const PAGE_EVENT = "/event";

export const PAGE_BROCHURE_REDMAX = "/brochure/redmax";
export const PAGE_BROCHURE_RUTA_ST = "/ces2025/dronerobotstation";
export const PAGE_CES2025_BOOTH = "/ces2025/booth";

export const Authority = {
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_MANAGER: "ROLE_MANAGER",
    ROLE_USER: "ROLE_USER",
};

//session
export const SESSION_USER_IP = "userIP";
export const SESSION_CURRENT_MODEL = "currentModel";
export const SESSION_AFTER_LOGIN_PATH = "afterLoginPath";
export const SESSION_BEFORE_LOGIN_PATH = "beforeLoginPath";
export const SESSION_RESERVATION_INFO = "reservationInfo";
export const SESSION_ORDER = "order";
export const DEPOSIT = 100000;

// TODO: get Index
export const CAMPING_CAR_CATEGORY_INDEX = 0;
export const CAMPING_CAR_MODEL_INDEX = 0;
export const CAMPING_CAR_TYPE_INDEX = 3;
export const KART_CATEGORY_INDEX = 1;
export const DRON_STATTION_CATEGORY_INDEX = 2;
export const SHUTTLE_CATEGORY_INDEX = 3;
export const REFRIGERATION_CAR_CATEGORY_INDEX = 4;

export const FORMAT_DATE = "YYYY-MM-DD";
export const FORMAT_SHORT_DATE = "YY-MM-DD";
export const FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm:ss";
export const FORMAT_DATE_WITH_DAY = "YYYY-MM-DD (ddd)";
export const FORMAT_SHORT_DATE_WITH_DAY = "YY-MM-DD (ddd)";
export const FORMAT_MONTH_DATE = "MM-DD";
export const NORMAL_CHECKIN_TIME = "09:00:00";
export const NORMAL_CHECKOUT_TIME = "14:00:00";
export const LATE_CHECKOUT_TIME = "18:00:00";

export const Deploy = {
    DEV_INTERNAL: "dev-internal",
    DEV_EXTERNAL: "dev-external",
    PROD: "prod",
};

export const Domain = {
    RUTA40: "ruta40",
    RENT: "rent",
};

export const Hostname = {
    RUTA40_DEV_INTERNAL: "ruta40-dev-internal",
    RUTA40_DEV_EXTERNAL: "ruta40-dev-external",
    RUTA40_PROD: "ruta40-prod",
    RENT_DEV_INTERNAL: "rent-dev-internal",
    RENT_DEV_EXTERNAL: "rent-dev-external",
    RENT_PROD: "rent-prod",
};

// Check REACT_APP_PROD_TEST_MODE in .env
export const ProdTestIPAddressList: Array<string> = ["118.45.190.141"];

export const PaymentType = {
    DEPOSIT: "DEPOSIT",
    DOWN_PAYMENT: "DOWN_PAYMENT",
    BALANCE: "BALANCE",
    SUBSIDY: "SUBSIDY",
    ADDITIONAL_DOWN_PAYMENT: "ADDITIONAL_DOWN_PAYMENT",
    ADDITIONAL_BALANCE: "ADDITIONAL_BALANCE",
    REGISTRATION_FEE: "REGISTRATION_FEE",
};

export const RentPaymentType = {
    RENT: "RENT",
    DELAYED_RETURN: "DELAYED_RETURN",
    VEHICLE_DAMAGE: "VEHICLE_DAMAGE",
    RENT_ITEM_DAMAGE: "RENT_ITEM_DAMAGE",
    RENT_ITEM_LOSS: "RENT_ITEM_LOSS ",
    INSURANCE: "INSURANCE",
};

export const PaymentMethod = {
    ONLINE: "ONLINE",
    OFFLINE_CARD: "OFFLINE_CARD",
    OFFLINE_BANK: "OFFLINE_BANK",
    OFFLINE_CASH: "OFFLINE_CASH",
};

export const AUTO_AWNING_NAME = "3.5미터 루프어닝(자동)";
export const MANUAL_AWNING_NAME = "3.5미터 루프어닝(수동)";

export const PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+=?{};':"|,.<>/~`[\]-])[a-zA-Z\d!@#$%^&*()_+=?{};':"|,.<>/~`[\]-]{8,}$/i;

export const EVENT_CLOSE_ALL_MODAL = "closeAllModal";
export const EVENT_CLOSE_ALL_DRAWER = "closeAllDrawer";
export const EVENT_CLOSE_MENU = "closeMenu";
export const EVENT_CLOSE_PICKER = "closePicker";

export const EXTERNAL_EVENT_BACK = "backPressEvent";
export const EXTERNAL_EVENT_RESUME = "resumeAppEvent";

export const PUSH_NOTIFICATION_BATTERY = "batteryPushNotification";
export const PUSH_NOTIFICATION_SUB_BATTERY = "subBatteryPushNotification";
export const PUSH_NOTIFICATION_DOOR_STATE = "doorStatePushNotification";
export const PUSH_NOTIFICATION_CAR_STATE = "carStatePushNotification";

export const EVENT_HEADER_INFO = "onInfoPressEvent";
export const EVENT_BATTERY = "onBatteryEvent";

export const URL_IOS_APP_STORE = "https://apps.apple.com/kr/app/id6449489838";
export const URL_ANDROID_PLAY_STORE = "https://play.google.com/store/apps/details?id=com.alpamotors.rt40.rent.android&hl=ko-KR";
export const URL_KAKAOTALK_CHANNEL_FRIEND = "https://pf.kakao.com/_WdQDb/friend";
export const URL_KAKAOTALK_CHANNEL_CHAT = "https://pf.kakao.com/_WdQDb/chat";
export const URL_KAKAOTALK_CHANNEL_JEJU_EVENT = "https://pf.kakao.com/_WdQDb/102656721";
export const URL_YOUTUBE_RT40 = "https://www.youtube.com/@rutas_ev";
export const URL_YOUTUBE_RENT = "https://www.youtube.com/@RUTA40_ev";
export const URL_INSTAGRAM = "https://www.instagram.com/ruta40.co.kr/";

export const URL_REVIEW_EVENT = "https://kko.to/H5is0d_rnu";
export const URL_SURVEY_EVENT = "https://kko.to/aA2PxG6w2d";
export const URL_QUIZ_EVENT = "https://kko.to/p6Q1UIXEJC";

export const INSURANCE_PER_DAY = 10000;
export const INSURANCE_START_RENT_PAYMENT_ITEM_ID = 93; // nt_rent_payment_item.rent_payment_item_id

export const EMPTY_PAGE = {
    content: [],
    pageable: {
        pageSize: 0,
        pageNumber: 0,
    },
    totalElements: 0,
    totalPages: 0,
};

export const BEST_REVIEW_BOARD_ID = 1;
export const USER_REVIEW_BOARD_ID = 2;

export const ENABLED_B2B_UI = Utils.checkDev();
