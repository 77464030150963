import { useNavigate } from "react-router";
import { AlertType } from "../provider/AlertProvider";
import { useAliveController } from "react-activation";
import { SortOrder } from "antd/lib/table/interface";

import * as Common from "../commons/common";
import * as TypeDTO from "../commons/typeDTO";
import * as Strings from "../commons/string";

import CryptoJS from "crypto-js";
import moment from "moment";
import Bayrun3 from "../assets/images/model/bayrun/BayrunType-exterior-1.jpg";
import Bayrun4 from "../assets/images/model/bayrun/BayrunType-exterior-rent.jpg";
import Bayrun4Mobile from "../assets/images/model/bayrun/BayrunType-exterior-rent-mobile.jpg";
import SType from "../assets/images/model/stype/SType-comingSoon.jpg";

declare const window: typeof globalThis & {
    Android: any;
    webkit: any;
};

export const checkHostName = () => {
    const hostname = window.location.origin;

    if (hostname.includes(process.env.REACT_APP_RENT_PROD_URI)) {
        return Common.Hostname.RENT_PROD;
    } else if (hostname.includes(process.env.REACT_APP_RUTA40_PROD_URI)) {
        return Common.Hostname.RUTA40_PROD;
    } else if (hostname.includes(process.env.REACT_APP_RENT_DEV_INTERNAL_URI)) {
        return Common.Hostname.RENT_DEV_INTERNAL;
    } else if (hostname.includes(process.env.REACT_APP_RUTA40_DEV_INTERNAL_URI)) {
        return Common.Hostname.RUTA40_DEV_INTERNAL;
    } else if (hostname.includes(process.env.REACT_APP_RENT_DEV_EXTERNAL_URI)) {
        return Common.Hostname.RENT_DEV_EXTERNAL;
    } else if (hostname.includes(process.env.REACT_APP_RUTA40_DEV_EXTERNAL_URI)) {
        return Common.Hostname.RUTA40_DEV_EXTERNAL;
    }

    return Common.Hostname.RUTA40_DEV_EXTERNAL;
};

export const checkDeploy = () => {
    const hostname = checkHostName();

    if (hostname === Common.Hostname.RUTA40_DEV_INTERNAL || hostname === Common.Hostname.RENT_DEV_INTERNAL) {
        return Common.Deploy.DEV_INTERNAL;
    } else if (hostname === Common.Hostname.RUTA40_DEV_EXTERNAL || hostname === Common.Hostname.RENT_DEV_EXTERNAL) {
        return Common.Deploy.DEV_EXTERNAL;
    } else if (hostname === Common.Hostname.RUTA40_PROD || hostname === Common.Hostname.RENT_PROD) {
        return Common.Deploy.PROD;
    } else return Common.Deploy.DEV_INTERNAL;
};

export const checkDev = () => {
    const hostname = checkHostName();

    if (
        hostname === Common.Hostname.RUTA40_DEV_INTERNAL ||
        hostname === Common.Hostname.RENT_DEV_INTERNAL ||
        hostname === Common.Hostname.RUTA40_DEV_EXTERNAL ||
        hostname === Common.Hostname.RENT_DEV_EXTERNAL
    ) {
        return true;
    }

    return false;
};

export const checkDomain = () => {
    const hostname = checkHostName();

    if (
        hostname === Common.Hostname.RUTA40_DEV_INTERNAL ||
        hostname === Common.Hostname.RUTA40_DEV_EXTERNAL ||
        hostname === Common.Hostname.RUTA40_PROD
    ) {
        return Common.Domain.RUTA40;
    } else if (
        hostname === Common.Hostname.RENT_DEV_INTERNAL ||
        hostname === Common.Hostname.RENT_DEV_EXTERNAL ||
        hostname === Common.Hostname.RENT_PROD
    ) {
        return Common.Domain.RENT;
    }

    return Common.Domain.RENT;
};

//modal
export const initModalStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_MODAL, String(0));
};

export const addModalStack = () => {
    let currStack = getModalStack();
    currStack++;
    sessionStorage.setItem(Common.SESSION_SHOW_MODAL, String(currStack));
};

export const popModalStack = () => {
    let currStack = getModalStack();
    currStack--;
    if (currStack < 0) currStack = 0;
    sessionStorage.setItem(Common.SESSION_SHOW_MODAL, String(currStack));
};

export const getModalStack = () => {
    return Number(sessionStorage.getItem(Common.SESSION_SHOW_MODAL));
};

//drawer
export const initDrawerStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_DRAWER, String(0));
};

export const addDrawerStack = () => {
    let currStack = getDrawerStack();
    currStack++;
    sessionStorage.setItem(Common.SESSION_SHOW_DRAWER, String(currStack));
};

export const popDrawerStack = () => {
    let currStack = getDrawerStack();
    currStack--;
    if (currStack < 0) currStack = 0;
    sessionStorage.setItem(Common.SESSION_SHOW_DRAWER, String(currStack));
};

export const getDrawerStack = () => {
    return Number(sessionStorage.getItem(Common.SESSION_SHOW_DRAWER));
};

//menu
export const initMenuStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_MENU, String(false));
};

export const openMenuStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_MENU, String(true));
};

export const closeMenuStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_MENU, String(false));
};

export const getMenuStack = () => {
    return sessionStorage.getItem(Common.SESSION_SHOW_MENU);
};

//datepicker
export const initPickerStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_PICKER, String(false));
};

export const openPickerStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_PICKER, String(true));
};

export const closePickerStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_PICKER, String(false));
};

export const getPickerStack = () => {
    return sessionStorage.getItem(Common.SESSION_SHOW_PICKER);
};

export const sendFCMToken = () => {
    const token = JSON.parse(getLocalStorage(Common.CONTEXT_AUTH) || "{}").token;
    const user = JSON.parse(getLocalStorage(Common.CONTEXT_AUTH) || "{}").user;
    if (token && user) {
        if (window.Android) window.Android.sendFCMToken(user.email, token);
        else if (window.webkit?.messageHandlers?.sendFCMToken) {
            const message = {
                username: user.email,
                loginToken: token,
            };

            window.webkit.messageHandlers.sendFCMToken.postMessage(message);
        } else console.log("Not Mobile App");
    }
};

export function onEvent(eventType: string, listener: (event?: any) => void) {
    document.addEventListener(eventType, listener);
}

export function offEvent(eventType: string, listener: (event?: any) => void) {
    document.removeEventListener(eventType, listener);
}

export function onceEvent(eventType: string, listener: (event?: any) => void) {
    onEvent(eventType, handleEventOnce);

    function handleEventOnce() {
        listener();
        offEvent(eventType, handleEventOnce);
    }
}

export function triggerEvent(eventType: string, data?: any) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}

export const convertCurrency = (price: number) => {
    const convert = "₩ " + new Intl.NumberFormat("ko-KR").format(price);
    return convert;
};

export const convertCurrencyKorean = (price: number) => {
    const convert = new Intl.NumberFormat("ko-KR").format(price) + "원";
    return convert;
};

export const convertOrderStatus = (status: string) => {
    let convert = "";
    switch (status) {
        case "DEPOSIT_WAITING":
            convert = "계약금 입금 대기중";
            break;

        case "DEPOSIT_PAID":
            convert = "계약금 입금 확인";
            break;

        case "CONTRACTING":
            convert = "계약";
            break;

        case "BALANCE_WAITING":
            convert = "잔금 입금 대기중";
            break;

        case "BALANCE_PAID":
            convert = "잔금 입금 대기중";
            break;

        case "FINAL_CONTRACTING":
            convert = "최종 계약";
            break;

        case "DELIVERED":
            convert = "출차";
            break;

        default:
            break;
    }

    return convert;
};

export const checkUnAuthorizedToken = (error: any | null) => {
    if (error === null) return false;

    if (error.response.data.code === AlertType.UNAUTHORIZED && error.response.data.message === "Unauthorized token") {
        return true;
    }

    return false;
};

export const checkAllOpenMode = (userEmail?: string) => {
    let allOpenMode = false;

    // if (Boolean(process.env.REACT_APP_PROD_TEST_MODE) === true) {
    //     const userIP = sessionStorage.getItem(Common.SESSION_USER_IP);
    //     if (userIP !== null) {
    //         if (Common.ProdTestIPAddressList.includes(userIP)) allOpenMode = true;
    //     }

    //     // 다날 테스트 코드
    //     if (userEmail === "judy2525@nate.com") allOpenMode = true;
    //     if (userEmail === "llsjkim7@naver.com") allOpenMode = true;
    // } else {
    //     allOpenMode = true;
    // }
    allOpenMode = true;

    return allOpenMode;
};

export const checkRentAllOpenMode = (userEmail?: string) => {
    let allOpenMode = false;

    // if (Boolean(process.env.REACT_APP_RENT_PROD_TEST_MODE) === true) {
    //     if (getCurrentTime().isSameOrAfter(Common.SITE_OPEN_DATE)) {
    //         allOpenMode = true;
    //     } else {
    //         const userIP = sessionStorage.getItem(Common.SESSION_USER_IP);

    //         if (userIP !== null) {
    //             if (Common.ProdTestIPAddressList.includes(userIP)) allOpenMode = true;
    //         }

    //         if (checkHostName() === Common.Hostname.RENT_DEV_INTERNAL || checkHostName() === Common.Hostname.RENT_DEV_EXTERNAL) {
    //             allOpenMode = true;
    //         }

    //         // if (window.Android) allOpenMode = true;
    //         // if (window.webkit) allOpenMode = true;

    //         // // 다날 테스트 코드
    //         if (userEmail === "judy2525@nate.com") allOpenMode = true;
    //         if (userEmail === "llsjkim7@naver.com") allOpenMode = true;
    //         if (userEmail === "jyim@emgglobla.co.kr") allOpenMode = true;
    //         if (userEmail === "dmg05089@naver.com") allOpenMode = true;
    //         if (userEmail === "hoon6961@gmail.com") allOpenMode = true;
    //         if (userEmail === "kkhsb@hanmail.net") allOpenMode = true;
    //         if (userEmail === "sjkim@emgglobal.co.kr") allOpenMode = true;
    //         if (userEmail === "ydjo@emgglobal.co.kr") allOpenMode = true;
    //         if (userEmail === "jhhong@emgglobal.co.kr") allOpenMode = true;
    //         if (userEmail === "jhan@emgglobal.co.kr") allOpenMode = true;
    //         if (userEmail === "winnerwoi@naver.com") allOpenMode = true;
    //         if (userEmail === "yesido0@nate.com") allOpenMode = true;
    //     }
    // } else {
    //     allOpenMode = true;
    // }
    allOpenMode = true;

    return allOpenMode;
};

export const useNavigateWithRefresh = () => {
    const navigate = useNavigate();
    const { clear } = useAliveController();

    const navigateWithRefresh = (path: string, state?: any) => {
        clear();
        setTimeout(() => {
            navigate(path, state);
        }, 100);
    };

    return { navigateWithRefresh };
};

export const geKoreanNumber = (number: number) => {
    const koreanUnits = ["", "만", "억", "조"];
    let answer = "";
    const unit = 10000;
    let index = 0;
    let division = Math.pow(unit, index);

    while (Math.floor(number / division) > 0) {
        const mod = Math.floor((number % (division * unit)) / division);
        if (mod) {
            const modToString = mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            answer = `${modToString}${koreanUnits[index]}` + (answer.length > 0 ? " " : "") + answer;
        }
        division = Math.pow(unit, ++index);
    }
    return answer;
};

export const getPaidAmountByPaymentType = (paymentType: string, payments?: Array<TypeDTO.PaymentDto>) => {
    let paidAmount = 0;

    if (payments !== undefined && payments.length > 0) {
        const filterValues = payments.filter((payment) => payment.paymentType === paymentType);
        if (filterValues.length > 0 && filterValues[0].amount) paidAmount = filterValues[0].amount;
    }

    return paidAmount;
};

export const isPaidByPaymentType = (paymentType: string, payments?: Array<TypeDTO.PaymentDto>) => {
    let isPaid = false;

    if (payments !== undefined && payments.length > 0) {
        const filterValues = payments.filter((payment) => payment.paymentType === paymentType);
        if (filterValues.length > 0 && filterValues[0].paidDate && filterValues[0].canceledDate === null && filterValues[0].failedDate === null)
            isPaid = true;
    }

    return isPaid;
};

export const getPaymentByPaymentType = (paymentType: string, payments?: Array<TypeDTO.PaymentDto>) => {
    let payment = null;

    if (payments !== undefined && payments.length > 0) {
        const filterValues = payments.filter((payment) => payment.paymentType === paymentType);
        if (filterValues.length > 0) payment = filterValues[0];
    }

    return payment;
};

export const getOrderStatus = (payments?: Array<TypeDTO.PaymentDto>) => {
    let status = "예약금 입금 전";

    if (payments !== undefined && payments.length > 0) {
        const paymentDeposit = payments.filter((payment) => payment.paymentType === Common.PaymentType.DEPOSIT);
        if (paymentDeposit.length > 0 && paymentDeposit[0].paidDate) status = "예약금 입금";

        // TODO: 지불상태 따른 상태 변경
    }

    return status;
};

export const convertPaymentType = (paymentType: string | undefined) => {
    let type = "";

    switch (paymentType) {
        case "DEPOSIT":
            type = Strings.paymentDeposit;
            break;
        case "DOWN_PAYMENT":
            type = Strings.paymentDownPayment;
            break;
        case "SUBSIDY":
            type = Strings.paymentSubsiby;
            break;
        case "BALANCE":
            type = Strings.paymentBalance;
            break;
        case "ADDITIONAL_DOWN_PAYMENT":
            type = Strings.paymentAdditionalDownPayment;
            break;
        case "ADDITIONAL_BALANCE":
            type = Strings.paymentAdditionalBalance;
            break;
        case "REGISTRATION_FEE":
            type = Strings.paymentRegistrationFee;
            break;

        default:
            break;
    }

    return type;
};

export const convertPaymentMethod = (paymentMethod: string | undefined) => {
    let method = "";

    switch (paymentMethod) {
        case Common.PaymentMethod.ONLINE:
            method = Strings.paymentOnline;
            break;
        case Common.PaymentMethod.OFFLINE_CARD:
            method = Strings.paymentOfflineCard;
            break;
        case Common.PaymentMethod.OFFLINE_BANK:
            method = Strings.paymentOfflineBank;
            break;
        case Common.PaymentMethod.OFFLINE_CASH:
            method = Strings.paymentOfflineCash;
            break;

        default:
            break;
    }

    return method;
};

const secretPass = "RUTA40";
export const getLocalStorage = (key: string) => {
    const item = localStorage.getItem(key);
    if (item === undefined || item === null) return item;

    const decrypted = CryptoJS.AES.decrypt(item, secretPass);

    try {
        const converted = decrypted.toString(CryptoJS.enc.Utf8);
        return converted;
    } catch (error) {
        removeLocalStorage(key);
        return undefined;
    }
};

export const setLocalStorage = (key: string, value: string) => {
    const encryped = CryptoJS.AES.encrypt(value, secretPass).toString();
    return localStorage.setItem(key, encryped);
};

export const removeLocalStorage = (key: string) => {
    return localStorage.removeItem(key);
};

export const getRentSiteUrl = () => {
    const deploy = checkDeploy();
    const httpPrefix = "http://";

    if (deploy === Common.Deploy.DEV_INTERNAL) {
        return httpPrefix + process.env.REACT_APP_RENT_DEV_INTERNAL_URI;
    } else if (deploy === Common.Deploy.DEV_EXTERNAL) {
        return httpPrefix + process.env.REACT_APP_RENT_DEV_EXTERNAL_URI;
    } else if (deploy === Common.Deploy.PROD) {
        return httpPrefix + process.env.REACT_APP_RENT_PROD_URI;
    } else return httpPrefix + process.env.REACT_APP_RENT_DEV_EXTERNAL_URI;
};

export const getRuta40SiteUrl = () => {
    const deploy = checkDeploy();
    const httpPrefix = "http://";

    if (deploy === Common.Deploy.DEV_INTERNAL) {
        return httpPrefix + process.env.REACT_APP_RUTA40_DEV_INTERNAL_URI;
    } else if (deploy === Common.Deploy.DEV_EXTERNAL) {
        return httpPrefix + process.env.REACT_APP_RUTA40_DEV_EXTERNAL_URI;
    } else if (deploy === Common.Deploy.PROD) {
        return httpPrefix + process.env.REACT_APP_RUTA40_PROD_URI;
    } else return httpPrefix + process.env.REACT_APP_RUTA40_DEV_EXTERNAL_URI;
};

export const utcToLocalTime = (utcTime: string, format: string) => {
    const localTime = moment.utc(utcTime).toDate();
    return moment(localTime).format(format);
};

export const getPriceFromOrderData = (orderData: TypeDTO.OrderDto) => {
    let carPrice = 0;
    let carPriceVAT = 0;
    if (orderData.carType !== undefined) {
        carPrice += orderData.carType.price;
        carPrice += orderData.carType.additionalPrice;
        // carPrice += orderData.carType.discountedPrice ? orderData.carType.discountedPrice : orderData.carType.price;
        // carPrice += orderData.carType.discountedAdditionalPrice ? orderData.carType.discountedAdditionalPrice : orderData.carType.additionalPrice;
    }
    if (orderData.color !== undefined) {
        carPrice += 0;
    }

    carPriceVAT = (carPrice / 100) * 10;

    if (orderData.options !== undefined) {
        orderData.options.forEach((option) => (carPrice += option.price));
    }

    /* 편의사항 숨김 */
    // let conveniencesPrice = 0;
    // if (orderData.conveniences !== undefined) {
    //     orderData.conveniences.forEach((convenience) => (conveniencesPrice += convenience.price));
    // }

    let subsidyPrice = 0;
    if (orderData.nationalSubsidy !== undefined && orderData.nationalSubsidy !== null) {
        subsidyPrice = orderData.nationalSubsidy.price;
    }

    let subsidySiGunPrice = 0;
    if (orderData.localSubsidy !== undefined) {
        subsidySiGunPrice = orderData.localSubsidy.price;
    }

    return { carPrice, carPriceVAT, subsidyPrice, subsidySiGunPrice };
};

export const useFocusOnEnter = (formRef: any) => {
    const onEnterKey = (event: any) => {
        if (event && event.keyCode && event.keyCode === 13 && event.target && event.target.form) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            for (let i = index + 1; i < formRef.current.length; i++) {
                if (formRef.current[i].tabIndex === -1) {
                    continue;
                }
                formRef.current[i].focus();
                if (document.activeElement === formRef.current[i]) {
                    break;
                }
            }
        }
    };

    return { onEnterKey };
};

export const getPaymentType = (payment: TypeDTO.PaymentDto) => {
    let paymentType = "-";

    if (payment.paymentMethod === "ONLINE" && payment.paymentImp && payment.paymentImp.cardName) {
        const stringQuota =
            payment.paymentImp.cardType === "0" ? (payment.paymentImp.cardQuota === 0 ? "/일시불" : `/${payment.paymentImp.cardQuota}개월`) : "/체크";
        paymentType = payment.paymentImp.cardName + stringQuota;
    } else if (payment.paymentMethod === "OFFLINE_CARD" && payment.paidDate) {
        paymentType = "신용카드";
    } else if (payment.paymentMethod === "OFFLINE_BANK" && payment.paidDate) {
        paymentType = "계좌이체";
    } else if (payment.paymentMethod === "OFFLINE_CASH" && payment.paidDate) {
        paymentType = "현금";
    } else if (payment.paymentImp?.pgProvider === "kakaopay") {
        paymentType = "카카오페이";
    }

    return paymentType;
};

export const getCarImage = (type?: string, isMobile?: boolean) => {
    if (type?.includes("S타입")) {
        return SType;
    } else if (type?.includes("3인승")) {
        return Bayrun3;
    } else if (type?.includes("4인승")) {
        return isMobile ? Bayrun4Mobile : Bayrun4;
    }

    return "";
};

export const getDiscountRate = (price?: number, discountPrice?: number) => {
    if (price === undefined || discountPrice === undefined || discountPrice === 0) {
        return 0;
    }

    return Math.round(((price - discountPrice) / price) * 100) + "%";
};

export const getDanalCPID = (paymentType: string) => {
    const deploy = checkDeploy();
    let cpid = "danal_tpay.9810030929";

    if (paymentType === "card" || paymentType === "trans") {
        if (deploy === Common.Deploy.PROD) {
            cpid = "danal_tpay.A310054452";
        }
    } else if (paymentType === "kakaopay") {
        cpid = "kakaopay";
    }

    return cpid;
};

export const convertSubBatteryStatus = (status: "NORMAL" | "LOW" | "VERY_LOW") => {
    let convetString = "";
    switch (status) {
        case "NORMAL": {
            convetString = "정상";
            break;
        }

        case "LOW": {
            convetString = "방전 주의";
            break;
        }

        case "VERY_LOW": {
            convetString = "방전 위험";
            break;
        }

        default:
            break;
    }

    return convetString;
};

export const convertRentType = (rent: TypeDTO.RentDto) => {
    if (rent?.canceledDate) {
        return Strings.cancellationComplete;
    } else if (rent.returnDate) {
        return Strings.useComplete;
    } else if (rent.usedDate) {
        return Strings.inUse;
    } else if (moment(rent?.endDate).isBefore(moment(), "day")) {
        return Strings.expiration;
    } else if (rent?.rentPayments?.find((payment) => payment.rentPaymentType === "RENT")?.paidDate) {
        return Strings.reservationComplete;
    } else {
        return Strings.dash;
    }
};

export const checkRentType = (rent: TypeDTO.RentDto, types: string[]) => {
    return types.find((type) => convertRentType(rent) === type) !== undefined;
};

export const getCurrentTime = () => {
    const gapSeconds = Number(sessionStorage.getItem(Common.CONTEXT_LOCAL_TIME_GAP));
    const convertTime = moment().add(gapSeconds, "seconds");
    // console.log("gapSeconds", gapSeconds);
    // console.log("getCurrentTime", moment().format(Common.FORMAT_DATE_TIME), convertTime.format(Common.FORMAT_DATE_TIME));
    return convertTime;
};

export const sendLinkToApp = (url: string) => {
    window.open(url);
    if (window.webkit?.messageHandlers?.openUrl) {
        const message = {
            url: url,
        };

        window.webkit.messageHandlers.openUrl.postMessage(message);
    }
};

export const sortString = (a: string, b: string, sortOrder?: SortOrder, fixed?: boolean): number => {
    if (fixed) return 0;
    if (a != null && b != null) {
        return a.localeCompare(b);
    }
    if (a) {
        return sortOrder === "ascend" ? -1 : 1;
    }
    if (b) {
        return sortOrder === "ascend" ? 1 : -1;
    }
    return 0;
};

export const countDays = (startDate: Date, endDate: Date, peakSeasons?: TypeDTO.RentPeakSeasonDto[]) => {
    const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let weekdays = 0;
    let weekends = 0;

    for (let i = 0; i < diffDays; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);
        const dayOfWeek = currentDate.getDay();
        const isPeakSeason =
            peakSeasons?.find((peakSeason) =>
                moment(currentDate).startOf("day").isBetween(peakSeason.startDate, peakSeason.endDate, undefined, "[]")
            ) !== undefined;

        if (dayOfWeek === 5 || dayOfWeek === 6 || isPeakSeason) {
            weekends++;
        } else {
            weekdays++;
        }
    }

    return { weekdays, weekends };
};
