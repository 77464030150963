import React, { useEffect, useState } from "react";
import { Col, Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import LayoutHeader from "../component/LayoutHeader";
import LayoutFooter from "../component/LayoutFooter";
import SubFooter from "../component/SubFooter";

import * as Common from "../commons/common";
import * as Utils from "../utils/utils";
import ModalBatteryState from "../component/rent/ModalBatteryState";
import ModalSubBatteryState from "../component/rent/ModalSubBatteryState";
import ModalDoorState from "../component/rent/ModalDoorState";
import ModalCarState from "../component/rent/ModalCarState";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import EngFooter from "../component/EngFooter";

declare const window: typeof globalThis & {
    Android: any;
};

function PageLayout({
    type,
    theme,
    children,
}: {
    type: "home" | "rent";
    theme?: { header?: "dark" | "light" | "none"; footer?: "dark" | "sub" | "eng" | "none"; scroll?: "none" };
    children: any;
}) {
    const { Header, Content, Footer } = Layout;
    const navigate = useNavigate();
    const [lineBanner, setLineBanner] = useState(true);
    const [triggerCloseMenu, setTriggerCloseMenu] = useState(false);

    useEffect(() => {
        if (lineBanner) {
            document.body.classList.add("line-banner");
        } else {
            document.body.classList.remove("line-banner");
        }
    }, [lineBanner]);

    const closeMenu = () => {
        setTriggerCloseMenu(!triggerCloseMenu);
    };

    useEffect(() => {
        window.addEventListener(Common.EXTERNAL_EVENT_BACK, onBackPressEvent);
        window.addEventListener(Common.EXTERNAL_EVENT_RESUME, onResumeAppEvent);

        window.addEventListener(Common.PUSH_NOTIFICATION_BATTERY, onBatteryPushNotification);
        window.addEventListener(Common.PUSH_NOTIFICATION_SUB_BATTERY, onSubBatteryPushNotification);
        window.addEventListener(Common.PUSH_NOTIFICATION_DOOR_STATE, onDoorPushNotification);
        window.addEventListener(Common.PUSH_NOTIFICATION_CAR_STATE, onCarPushNotification);

        Utils.onEvent(Common.EVENT_BATTERY, onBatteryEvent);

        return () => {
            window.removeEventListener(Common.PUSH_NOTIFICATION_BATTERY, onBatteryPushNotification);
            window.removeEventListener(Common.PUSH_NOTIFICATION_SUB_BATTERY, onSubBatteryPushNotification);
            window.removeEventListener(Common.PUSH_NOTIFICATION_DOOR_STATE, onDoorPushNotification);
            window.removeEventListener(Common.PUSH_NOTIFICATION_CAR_STATE, onCarPushNotification);

            window.removeEventListener(Common.EXTERNAL_EVENT_BACK, onBackPressEvent);
            window.removeEventListener(Common.EXTERNAL_EVENT_RESUME, onResumeAppEvent);

            Utils.offEvent(Common.EVENT_BATTERY, onBatteryEvent);
        };
    }, [location, triggerCloseMenu]);

    const onBackPressEvent = async () => {
        console.log("onBackPressEvent", location.pathname);

        if (Utils.getPickerStack() === "true") {
            Utils.triggerEvent(Common.EVENT_CLOSE_PICKER);
            Utils.initPickerStack();
        } else if (Utils.getModalStack() !== 0) {
            Utils.triggerEvent(Common.EVENT_CLOSE_ALL_MODAL);
            Utils.initModalStack();
        } else if (Utils.getDrawerStack() !== 0) {
            Utils.triggerEvent(Common.EVENT_CLOSE_ALL_DRAWER);
            Utils.initDrawerStack();
        } else if (Utils.getMenuStack() === "true") {
            Utils.triggerEvent(Common.EVENT_CLOSE_MENU);
            Utils.initMenuStack();
        } else {
            const path = location.pathname;

            switch (path) {
                case Common.PAGE_HOME:
                    window.Android.closeAppOnBackPress();
                    break;
                default:
                    navigate(-1);
                    break;
            }
        }
    };

    const onResumeAppEvent = async () => {
        console.log("onResumeAppEvent");
        //TODO
    };

    const onBatteryPushNotification = async (event: any) => {
        console.log("onBatteryPushNotification title", event.detail.title);
        console.log("onBatteryPushNotification body", event.detail.body);
        console.log("onBatteryPushNotification battery", event.detail.battery);

        // TODO:
        showModalBattery(event.detail.battery);
    };
    const onSubBatteryPushNotification = async (event: any) => {
        console.log("onSubBatteryPushNotification title", event.detail.title);
        console.log("onSubBatteryPushNotification body", event.detail.body);
        console.log("onSubBatteryPushNotification subBattery", event.detail.subBattery);

        // TODO:
        showModalSubBattery(event.detail.subBattery);
    };

    const onDoorPushNotification = async (event: any) => {
        console.log("onDoorPushNotification title", event.detail.title);
        console.log("onDoorPushNotification body", event.detail.body);
        console.log("onDoorPushNotification doorStatus", event.detail.doorStatus);

        // TODO:
        showModalDoorState(event.detail.doorStatus);
    };

    const onCarPushNotification = async (event: any) => {
        console.log("onCarPushNotification title", event.detail.title);
        console.log("onCarPushNotification body", event.detail.body);
        console.log("onCarPushNotification keyOffStatus", event.detail.keyOffStatus);

        // TODO:
        showModalCarState(event.detail.keyOffStatus);
    };

    const onBatteryEvent = async (event: any) => {
        setAllBatteryStatus(event.detail.battery, event.detail.subBattery);
    };

    const showModalBattery = (battery: number) => {
        if (battery <= 40) {
            setBatteryState(battery);
            setOpenModalBatteryState(true);
        }
    };

    const showModalSubBattery = (subBattery: string) => {
        if (subBattery.toUpperCase() === "LOW" || subBattery.toUpperCase() === "VERY_LOW") {
            setSubBatteryState(subBattery);
            setOpenModalSubBatteryState(true);
        }
    };

    const showModalDoorState = (doorStatus: string) => {
        if (
            doorStatus.toUpperCase() === "OPEN" ||
            doorStatus.toUpperCase() === "CLOSED" ||
            doorStatus.toUpperCase() === "FAIL_NOT_P" ||
            doorStatus.toUpperCase() === "S_OPEN" ||
            doorStatus.toUpperCase() === "S_CLOSED" ||
            doorStatus.toUpperCase() === "S_FAIL_NOT_P"
        ) {
            setDoorState(doorStatus);
            setOpenModalDoorState(true);
        }
    };

    const showModalCarState = (keyOffStatus: string) => {
        if (keyOffStatus.toUpperCase() === "KEYOFF_NOT_P") {
            setCarState(keyOffStatus);
            setOpenModalCarState(true);
        }
    };

    const setAllBatteryStatus = (battery: number, subBattery: string) => {
        showModalBattery(battery);
        showModalSubBattery(subBattery);
    };

    const [batteryState, setBatteryState] = useState<number>();
    const [openModalBatteryState, setOpenModalBatteryState] = useState(false);

    const [subBatteryState, setSubBatteryState] = useState<string>();
    const [openModalSubBatteryState, setOpenModalSubBatteryState] = useState(false);

    const [doorState, setDoorState] = useState<string>();
    const [openModalDoorState, setOpenModalDoorState] = useState(false);

    const [carState, setCarState] = useState<string>();
    const [openModalCarState, setOpenModalCarState] = useState(false);

    const onClickLineBanner = () => {
        if (type === "rent") {
            navigate(Common.PAGE_NOTICE_DETAIL + "/17");
        } else if (type === "home") {
            navigate(Common.PAGE_NOTICE_DETAIL + "/9");
        }
    };

    return (
        <Layout>
            {theme?.header !== "none" && (
                <Header style={{ padding: 0, height: "75px", display: "contents" }}>
                    {lineBanner && (
                        <div className="lineBannerWrapper">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 22, offset: 1 }}
                                lg={{ span: 18, offset: 3 }}
                                xl={{ span: 16, offset: 4 }}
                                xxl={{ span: 14, offset: 5 }}
                                className="lineBanner"
                                onClick={() => {
                                    onClickLineBanner();
                                }}
                            >
                                <p>
                                    지금 회원가입 하면, 렌트가 <b>1박당 10만원 할인!</b>
                                </p>

                                <CloseOutlined
                                    className="lineBannerBtn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setLineBanner(false);
                                    }}
                                />
                            </Col>
                        </div>
                    )}
                    <LayoutHeader
                        lineBanner={lineBanner}
                        type={type}
                        theme={theme?.header === undefined ? "dark" : "light"}
                        closeMenuEvent={triggerCloseMenu}
                    />
                </Header>
            )}
            <Content
                onClick={closeMenu}
                className={theme?.scroll === "none" ? "scrollNone" : ""}
                style={{
                    paddingTop:
                        theme?.header === "none"
                            ? "0"
                            : theme?.header !== "light"
                            ? `calc(75px + ${lineBanner ? "40px" : "0px"} )`
                            : `calc(0px + ${lineBanner ? "40px" : "0px"} )`,
                }}
            >
                <div
                    style={{
                        height:
                            theme?.scroll === "none"
                                ? theme.header === "none"
                                    ? "100vh"
                                    : `calc(100vh - (${lineBanner ? "115px" : "75px"}) )`
                                : "auto",
                    }}
                >
                    {children}
                </div>
            </Content>
            {theme?.footer === undefined ? (
                <LayoutFooter type={type} />
            ) : theme.footer === "sub" ? (
                <SubFooter />
            ) : theme.footer === "eng" ? (
                <EngFooter />
            ) : (
                ""
            )}

            <ModalBatteryState
                batteryLevel={batteryState}
                openModalBatteryState={openModalBatteryState}
                onChangedOpenModalBatteryState={(opened) => setOpenModalBatteryState(opened)}
            />

            <ModalSubBatteryState
                subBatteryState={subBatteryState}
                openModalSubBatteryState={openModalSubBatteryState}
                onChangedOpenModalSubBatteryState={(opened) => setOpenModalSubBatteryState(opened)}
            />

            <ModalDoorState
                doorState={doorState}
                openModalDoorState={openModalDoorState}
                onChangedOpenModalDoorState={(opened) => setOpenModalDoorState(opened)}
            />

            <ModalCarState
                carState={carState}
                openModalCarState={openModalCarState}
                onChangedOpenModalCarState={(opened) => setOpenModalCarState(opened)}
            />
        </Layout>
    );
}

export default PageLayout;
