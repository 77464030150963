import { Spin, Tabs, Col } from "antd";
import React, { useState } from "react";

import ModelDroneStation from "./ModelDroneStationRT60";
import ModelDroneStationST1 from "./ModelDroneStationST1";
import ScrollToTopOnMount from "./ScrollTopOnMount";

import Banner from "../assets/images/home/ruta40/banner_drone_station.jpg";

function ModelDroneStations() {
    const [currentTypeId, setCurrentTypeId] = useState<number>(1);

    const displayTypes = () => {
        return (
            <>
                <div className="header-container model">
                    <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                        <div className="row justify-content-center">
                            <div className="fadeIn text-center mb-5">
                                <h5 className="text-center text-light px-3 mb-4">드론 스테이션</h5>
                                <h1 className="text-light">DRONE STATION</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs
                    className="model-tabs tab-lg title model-body"
                    activeKey={currentTypeId !== undefined ? String(currentTypeId) : undefined}
                    items={[
                        { key: "1", label: "RUTA-ST1" },
                        { key: "2", label: "RT60" },
                    ]}
                    onChange={(value) => setCurrentTypeId(Number(value))}
                ></Tabs>

                <Col>
                    {currentTypeId === 1 && <ModelDroneStationST1 />}
                    {currentTypeId === 2 && <ModelDroneStation />}
                </Col>
            </>
        );
    };

    return (
        <div>
            {currentTypeId ? (
                <>
                    <ScrollToTopOnMount />
                    {displayTypes()}
                </>
            ) : (
                <div style={{ width: "100%", height: "calc(100vh - 75px)", textAlign: "center" }}>
                    <Spin
                        className="loading"
                        size="large"
                        tip={
                            <>
                                <p>LOADING...</p>
                                <span>잠시만 기다려 주세요.</span>
                            </>
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default ModelDroneStations;
