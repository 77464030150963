import React, { useState } from "react";
import { Col, Skeleton } from "antd";
import { Carousel } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import styles from "./Brochure.module.css";
import Gallery from "../../component/Gallery";
import ReactPlayer from "react-player";

import mobileImage2 from "../../assets/images/brochure/st1/brochure_redmax_mobile2.jpg";
import mobileImage3 from "../../assets/images/brochure/st1/brochure_redmax_mobile3.jpg";

import browserImage1 from "../../assets/images/brochure/st1/brochure_st1_browser1.jpg";
import browserImage2 from "../../assets/images/brochure/st1/brochure_st1_browser2.jpg";

import galleryImage1 from "../../assets/images/brochure/st1/gallery1.jpg";
import galleryImage2 from "../../assets/images/brochure/st1/gallery2.jpg";
import galleryImage3 from "../../assets/images/brochure/st1/gallery3.jpg";
import galleryImage4 from "../../assets/images/brochure/st1/gallery4.jpg";
import galleryImage5 from "../../assets/images/brochure/st1/gallery5.jpg";
import galleryImage6 from "../../assets/images/brochure/st1/gallery6.jpg";
import galleryImage7 from "../../assets/images/brochure/st1/gallery7.jpg";
import galleryImage8 from "../../assets/images/brochure/st1/gallery8.jpg";
import galleryImage9 from "../../assets/images/brochure/st1/gallery9.jpg";

import optionImage1 from "../../assets/images/brochure/st1/option1.jpg";
import optionImage2 from "../../assets/images/brochure/st1/option2.jpg";
import optionImage3 from "../../assets/images/brochure/st1/option3.jpg";
import optionImage4 from "../../assets/images/brochure/st1/option4.jpg";

import video1 from "../../assets/images/brochure/st1/video1.mp4";

function BrochureRutaSt() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const getOptionImage = (array: Array<string>) => {
        return array.map((image, index) => {
            return (
                <Carousel.Item key={index}>
                    <img className="d-block w-100 img-fluid" alt="First slide" src={image} />
                </Carousel.Item>
            );
        });
    };
    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <MobileView className="mobile">
                <div className={styles.mobileLayout}>
                    <div className={styles.video1Wrapper}>
                        <video height={"100%"} width={"100%"} className={styles.video} src={video1} muted autoPlay playsInline />
                        <div className={styles.video2Wrapper}>
                            {isLoading && <Skeleton.Node active={true} style={{ width: "calc(100vw - 40px)", height: "50vw" }} />}
                            <ReactPlayer
                                url={"https://youtu.be/MCgjbr8dbQc"}
                                controls
                                playing
                                muted
                                loop
                                width={"100%"}
                                height={"50vw"}
                                style={{ display: isLoading ? "none" : "block" }}
                                onReady={() => setIsLoading(false)}
                            />
                        </div>
                    </div>

                    <div className={styles.galleryArea} style={{ background: "white" }}>
                        <div className={styles.galleryWrapper} style={{ paddingBottom: 50 }}>
                            <Gallery
                                styles="brochure_gallery"
                                images={[
                                    galleryImage1,
                                    galleryImage2,
                                    galleryImage3,
                                    galleryImage4,
                                    galleryImage5,
                                    galleryImage6,
                                    galleryImage7,
                                    galleryImage8,
                                    galleryImage9,
                                ]}
                            />
                        </div>
                        <img className={styles.backgroundImage} src={mobileImage2} />
                    </div>
                    <div className={styles.carouselArea}>
                        <div className={styles.carouselWrapper}>
                            <Carousel variant="light">{getOptionImage([optionImage1, optionImage2, optionImage3, optionImage4])}</Carousel>
                        </div>
                    </div>
                    <img className={styles.backgroundImage} src={mobileImage3} />
                </div>
            </MobileView>

            <BrowserView className="browser">
                <Col xs={{ span: 24 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className={styles.browserLayout}>
                    <img className={styles.backgroundImage} src={browserImage1} />

                    {isLoading && <Skeleton.Node active={true} style={{ width: "calc(100vw - 40px)", height: "50vw" }} />}
                    <ReactPlayer
                        url={"https://youtu.be/MCgjbr8dbQc"}
                        controls
                        playing
                        muted
                        loop
                        width={"100%"}
                        height={"42vw"}
                        style={{ display: isLoading ? "none" : "block" }}
                        onReady={() => setIsLoading(false)}
                    />

                    <div className={styles.galleryArea}>
                        <div className={styles.galleryWrapper}>
                            <Gallery
                                styles="brochure_gallery browser"
                                images={[
                                    galleryImage1,
                                    galleryImage2,
                                    galleryImage3,
                                    galleryImage4,
                                    galleryImage5,
                                    galleryImage6,
                                    galleryImage7,
                                    galleryImage8,
                                    galleryImage9,
                                ]}
                            />
                        </div>
                    </div>
                    <img className={styles.backgroundImage} src={browserImage2} />
                </Col>
            </BrowserView>
        </div>
    );
}

export default BrochureRutaSt;
