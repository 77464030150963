import React, { useState, useEffect } from "react";
import { Col, Button, Modal, Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { useAuthState } from "../../provider/AuthProvider";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import RentPopup from "../../component/RentPopup";
import KakaotalkPopup from "../../component/KakaotalkPopup";
import CustomArrow from "../../component/CustomArrow";
import moment from "moment";

function Home() {
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const { navigateWithRefresh } = Utils.useNavigateWithRefresh();

    const [allCategories, setAllCategories] = useState<Array<TypeDTO.CarCategoryDto>>();
    const [openRentPopup, setOpenRentPopup] = useState(true);
    const [openKakaotalkPopup, setOpenKakaotalkPopup] = useState(true);

    const contentStyle: React.CSSProperties = {
        height: "calc(var(--vh, 1vh) * 100)",
        width: "100%",
        textAlign: "center",
        color: "#000",
        backgroundColor: "black",
        position: "relative",
    };

    useEffect(() => {
        requestAllModels();
        const today = moment().format(Common.FORMAT_DATE_TIME);

        // 팝업 날짜 여기서 수정 🔽
        const rentPeriod = moment("2023-08-01").format(Common.FORMAT_DATE_TIME);
        const rentPopupClosePeriod = window.localStorage.getItem("rentPopupClosePeriod");

        const eventPeriod = moment("2024-12-31").format(Common.FORMAT_DATE_TIME);
        const kakaotalkPopupClosePeriod = window.localStorage.getItem("kakaotalkPopupClosePeriod");

        if (rentPeriod > today) {
            setOpenRentPopup(false);
        } else if (rentPopupClosePeriod) {
            const obj = JSON.parse(rentPopupClosePeriod);
            const expire = moment(obj.expire).format(Common.FORMAT_DATE_TIME);

            if (expire > today) {
                setOpenRentPopup(false);
            } else {
                window.localStorage.removeItem("popupClosePeriod");
                setOpenRentPopup(true);
            }
        }

        if (eventPeriod < today) {
            setOpenKakaotalkPopup(false);
        } else if (kakaotalkPopupClosePeriod) {
            const obj = JSON.parse(kakaotalkPopupClosePeriod);
            const expire = moment(obj.expire).format(Common.FORMAT_DATE_TIME);

            if (expire > today) {
                setOpenKakaotalkPopup(false);
            } else {
                window.localStorage.removeItem("kakaotalkPopupClosePeriod");
                setOpenKakaotalkPopup(true);
            }
        }
    }, []);

    const requestAxiosAllCategories = async () => {
        const response = await utilAxiosWithAppToken().get(Request.CAR_CATEGORY_LIST_URL);
        return response.data;
    };

    const {
        loading: loadingAllCategories,
        error: errorAllCategories,
        data: resultAllCategories,
        execute: requestAllModels,
    } = useAsyncAxios(requestAxiosAllCategories);

    useEffect(() => {
        if (!resultAllCategories) return;
        console.log("resultAllCategories", resultAllCategories);

        setAllCategories(resultAllCategories.carCategories);
    }, [resultAllCategories]);

    useEffect(() => {
        if (!errorAllCategories) return;
        console.log("errorAllCategories", errorAllCategories);
    }, [errorAllCategories]);

    const noModelnoCatalog = () => {
        Modal.info({
            title: "오픈 예정",
            content: "해당 모델은 서비스 준비중입니다.\n정식 오픈까지 기대해 주세요.",
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    const noModel = () => {
        Modal.info({
            title: "판매 종료",
            content: "해당 모델은 준비된 물량이 모두 소진되어 구매하실 수 없습니다.\n신규 모델을 기대해 주세요.",
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    const handleModel = (modelId: number) => {
        navigate(Common.PAGE_MODEL + "/" + modelId);
    };

    const handleModelOrder = (modelId: number, typeId: number) => {
        if (Utils.checkAllOpenMode(userDetails?.user?.email)) {
            navigateWithRefresh(Common.PAGE_ORDER + "/" + modelId);
        } else {
            noModel();
        }
    };

    const settings = {
        nextArrow: <CustomArrow type="next" onClick={() => {}} />,
        prevArrow: <CustomArrow type="prev" onClick={() => {}} />,
    };

    return (
        <>
            {openRentPopup && (
                <RentPopup
                    open={true}
                    onChangedOpen={(opened) => {
                        setOpenRentPopup(opened);
                    }}
                    className={openKakaotalkPopup ? "secondPopup" : ""}
                />
            )}
            {openKakaotalkPopup && (
                <KakaotalkPopup
                    open={true}
                    onChangedOpen={(opened) => {
                        setOpenKakaotalkPopup(opened);
                    }}
                />
            )}
            <Col span={24}>
                <Carousel style={{ height: "100vh" }} draggable={true} arrows={true} {...settings} autoplay autoplaySpeed={5000} effect="fade">
                    <div>
                        <div id="sType" className="homeImage1" style={contentStyle}>
                            {allCategories && (
                                <div className="fadeIn home-title">
                                    <h5>{allCategories[Common.CAMPING_CAR_CATEGORY_INDEX].categoryName}</h5>
                                    <h2>{allCategories[Common.CAMPING_CAR_CATEGORY_INDEX].models[Common.CAMPING_CAR_MODEL_INDEX].modelName}</h2>
                                </div>
                            )}

                            {allCategories && (
                                <div className="swiperButton">
                                    <Button
                                        className="fadeInLeft btn-secondary"
                                        onClick={() =>
                                            handleModel(
                                                allCategories[Common.CAMPING_CAR_CATEGORY_INDEX].models[Common.CAMPING_CAR_MODEL_INDEX].modelId
                                            )
                                        }
                                    >
                                        자세히 보기
                                    </Button>
                                    <Button
                                        className="fadeInRight btn-primary"
                                        onClick={() =>
                                            handleModelOrder(
                                                allCategories[Common.CAMPING_CAR_CATEGORY_INDEX].models[Common.CAMPING_CAR_MODEL_INDEX].modelId,
                                                allCategories[Common.CAMPING_CAR_CATEGORY_INDEX].models[Common.CAMPING_CAR_MODEL_INDEX].types[
                                                    Common.CAMPING_CAR_TYPE_INDEX
                                                ].typeId
                                            )
                                        }
                                    >
                                        구매하기
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div id="cycleKart" className="homeImage3" style={contentStyle}>
                            {allCategories && (
                                <div className="fadeIn home-title">
                                    <h5>{allCategories[Common.KART_CATEGORY_INDEX].categoryName}</h5>
                                    <h2>{allCategories[Common.KART_CATEGORY_INDEX].models[0].modelName}</h2>
                                </div>
                            )}
                            {allCategories && (
                                <div className="swiperButton">
                                    <Button
                                        className="fadeInLeft btn-secondary"
                                        onClick={() => handleModel(allCategories[Common.KART_CATEGORY_INDEX].models[0].modelId)}
                                    >
                                        자세히 보기
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div id="refrigerator" className="homeImage5" style={contentStyle}>
                            {allCategories && (
                                <div className="fadeIn home-title">
                                    <h5>{allCategories[Common.REFRIGERATION_CAR_CATEGORY_INDEX].categoryName}</h5>
                                    <h2>{allCategories[Common.REFRIGERATION_CAR_CATEGORY_INDEX].models[0].modelName}</h2>
                                </div>
                            )}
                            <div className="swiperButton">
                                {allCategories && (
                                    <Button
                                        className="fadeInLeft btn-secondary"
                                        onClick={() => handleModel(allCategories[Common.REFRIGERATION_CAR_CATEGORY_INDEX].models[0].modelId)}
                                    >
                                        자세히 보기
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="droneStation" className="homeImage2" style={contentStyle}>
                            {allCategories && (
                                <div className="fadeIn home-title">
                                    <h5>{allCategories[Common.DRON_STATTION_CATEGORY_INDEX].categoryName}</h5>
                                    <h2>RUTA-ST1</h2>
                                </div>
                            )}
                            {allCategories && (
                                <div className="swiperButton">
                                    <Button
                                        className="fadeInLeft btn-secondary"
                                        onClick={() => handleModel(allCategories[Common.DRON_STATTION_CATEGORY_INDEX].models[0].modelId)}
                                    >
                                        자세히 보기
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div id="shuttle" className="homeImage4" style={contentStyle}>
                            {allCategories && (
                                <div className="fadeIn home-title">
                                    <h5>{allCategories[Common.SHUTTLE_CATEGORY_INDEX].categoryName}</h5>
                                    <h2>{allCategories[Common.SHUTTLE_CATEGORY_INDEX].models[0].modelName}</h2>
                                </div>
                            )}
                            {allCategories && (
                                <div className="swiperButton">
                                    <Button className="fadeInLeft btn-secondary" onClick={noModelnoCatalog}>
                                        자세히 보기
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Carousel>
            </Col>
        </>
    );
}

export default Home;
