import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { useAuthState } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import ModelCycleKart from "../../component/ModelCycleKart";
import ModelDroneStations from "../../component/ModelDroneStations";
import ModelRefrigerator from "../../component/ModelRefrigerator";
import ScrollToTopOnMount from "../../component/ScrollTopOnMount";
import ModelRuta40 from "../../component/ModelRuta40";

function Model() {
    const params = useParams();
    const userDetails = useAuthState();
    const { navigateWithRefresh } = Utils.useNavigateWithRefresh();

    const [modelId, setModelId] = useState<number>();
    const [modelInfo, setModelInfo] = useState<TypeDTO.CarModelDto>();

    const noModel = () => {
        Modal.info({
            title: "판매 종료",
            content: "해당 모델은 준비된 물량이 모두 소진되어 구매하실 수 없습니다.\n신규 모델을 기대해 주세요.",
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    useEffect(() => {
        setModelId(Number(params.modelId));
    }, [params.modelId]);

    useEffect(() => {
        if (modelId !== undefined) requestModelInfo();
    }, [modelId]);

    const requestAxiosModelInfo = async () => {
        const response = await utilAxiosWithAppToken().get(Request.CAR_MODEL_URL + "/" + modelId);
        return response.data;
    };

    const {
        loading: loadingModelInfo,
        error: errorModelInfo,
        data: resultModelInfo,
        execute: requestModelInfo,
    } = useAsyncAxios(requestAxiosModelInfo);

    useEffect(() => {
        if (!resultModelInfo) return;
        console.log("resultModelInfo", resultModelInfo);

        setModelInfo(resultModelInfo.carModel);
    }, [resultModelInfo]);

    useEffect(() => {
        if (!errorModelInfo) return;
        console.log("errorModelInfo", errorModelInfo);
    }, [errorModelInfo]);

    // TODO: change modelId
    const displayModel = () => {
        if (!modelId) return null;

        switch (modelId) {
            case 1:
                return (
                    <ModelRuta40
                        modelInfo={modelInfo}
                        onClickOrder={(typeId) => {
                            if (Utils.checkAllOpenMode(userDetails?.user?.email)) {
                                navigateWithRefresh(Common.PAGE_ORDER + "/" + modelId, {
                                    state: typeId,
                                });
                            } else {
                                noModel();
                            }
                        }}
                    />
                );

            case 2:
                return <ModelCycleKart />;

            case 3:
                return <ModelDroneStations />;

            case 5:
                return <ModelRefrigerator />;

            default:
                break;
        }

        return null;
    };

    return (
        <div>
            <ScrollToTopOnMount />
            {displayModel()}
        </div>
    );
}

export default Model;
