import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";
import Accordion from "react-bootstrap/Accordion";

function Faq() {
    const [faqs, setFaqs] = useState<Array<TypeDTO.FaqDto>>([]);

    useEffect(() => {
        requestGetFaqs();
    }, []);

    const requestAxiosGetFaqs = async () => {
        const response = await utilAxiosWithAppToken().get(Request.FAQS_URL);
        return response.data;
    };

    const { error: errorGetFaqs, data: resultGetFaqs, execute: requestGetFaqs } = useAsyncAxios(requestAxiosGetFaqs);

    useEffect(() => {
        if (!resultGetFaqs) return;
        console.log("resultGetFaqs", resultGetFaqs);
        setFaqs(resultGetFaqs.faqs);
    }, [resultGetFaqs]);

    useEffect(() => {
        if (!errorGetFaqs) return;
        console.log("errorGetFaqs", errorGetFaqs);
    }, [errorGetFaqs]);

    const displayFaq = () => {
        if (faqs.length > 0) {
            return (
                <>
                    {faqs.map((faq, index) => (
                        <Accordion.Item key={faq.faqId} eventKey={String(index)} className="accordion-item">
                            <Accordion.Header>{faq.faqQuestion}</Accordion.Header>
                            <Accordion.Body>
                                <ul style={{ padding: 0 }}>
                                    {faq.faqAnswer.split("\n").map((line, index) => (
                                        <li
                                            style={{ whiteSpace: "pre-wrap", listStyle: "none" }}
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                                __html: line.replace(". -", `.\n -`),
                                            }}
                                        />
                                    ))}
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </>
            );
        }
        return null;
    };

    return (
        <>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">자주 하는 질문</h5>
                    <h1 className="text-light">FAQ</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <section className={`row legal`}>
                        <Accordion alwaysOpen defaultActiveKey={["0"]} className="support">
                            {faqs ? (
                                displayFaq()
                            ) : (
                                <>
                                    <Accordion.Item eventKey="0" className="accordion-item">
                                        <Accordion.Header style={{ marginTop: "0" }}>차량 구동 방식이 뭔가요?</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>차량의 구동방식은 후륜 구동 방식입니다.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1" className="accordion-item">
                                        <Accordion.Header>차량 높이가 얼마인가요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    차량의 높이는 2.8m 이므로 안전을 고려하여 고속도로나 건물 진입 시 높이 제한이 3.0m 이상인 곳만
                                                    지나가야 합니다.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2" className="accordion-item">
                                        <Accordion.Header>충전구가 무엇인가요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>충전구는 DC 콤보 CCS1 를 사용하고 있습니다.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3" className="accordion-item">
                                        <Accordion.Header>메인 배터리 충전 시간이 얼마나 걸리나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>모든 전기차 충전기는 1회 충전 시 1시간으로 제한이 걸려 있습니다.</li>
                                                <li>급속 충전기 (100KW)에서 시간당 60%정도 충전이 됩니다.</li>
                                                <li>충전기의 용량과 날씨(온도)에 따라 충전 속도가 다를 수 있습니다.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="4" className="accordion-item">
                                        <Accordion.Header>차량을 개조해서 사용해도 되나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>차량 인도일로부터 3개월 이내에는 차량 개조를 하지 마세요.</li>
                                                <li>
                                                    전기 계통과 관련된 사항을 수리 또는 변경할 경우 반드시 주식회사 루타스와 사전협의하여 진행해
                                                    주세요.
                                                </li>
                                                <li>
                                                    차량 인도일 3개월 이내 임의 개조하거나 주식회사 루타스와 사전협의 없이 개조한 차량에 발생한 모든
                                                    문제와 비용은 주식회사 루타스에서 책임지지 않습니다.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="5" className="accordion-item">
                                        <Accordion.Header>차량 인테리어 및 운행 관련 문의는 어디로 하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    차량 인테리어 및 운행 관련 안내는 주식회사 루타스에 문의해 주세요.
                                                    <br />
                                                    070-7707-1768 (평일 09:00~17:00, 휴게시간 12:00~13:00)
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="6" className="accordion-item">
                                        <Accordion.Header>차량계약 후 해지할 경우 환불 받을 수 있나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    차량 출고전 해지 요청할 경우 계약금 환불이 가능하며, 자세한 사항은 고객센터(070-7707-1768) 문의해
                                                    주세요.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="7" className="accordion-item">
                                        <Accordion.Header>전기차 세차 시 주의사항이 있나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>하부 세차 및 보닛 직수 세차를 하지 마십시오. 손 세차를 권장합니다.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="8" className="accordion-item">
                                        <Accordion.Header>차량 전체 문은 어떻게 잠글수 있나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>메인 전원을 켠 상태에서 운전석 도어 왼쪽에 있는 문 잠금 버튼을 누릅니다.</li>
                                                <li>메인 전원을 켠 상태에서 차량 리모컨 키의 문 잠금 버튼을 누릅니다.</li>
                                                <li>메인 전원을 켠 상태에서 RUTA40 앱의 문잠금 버튼을 누릅니다. (향후 지원 예정)</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="9" className="accordion-item">
                                        <Accordion.Header>전기차 배터리 충전은 어떻게 하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul style={{ listStyle: "number" }}>
                                                <li>
                                                    충전기에서 충전할 금액을 지정 후 충전 방식을 선택합니다.
                                                    <br />- 충전기마다 충전 시작 전까지 메뉴가 상이 합니다. 안내되는 멘트를 듣고 따라 해 주세요.
                                                </li>
                                                <li>
                                                    운전석 문을 열고 충전구 캡을 오픈합니다.
                                                    <br />- 운전석 문을 열여야 충전구 캡을 오픈할 수 있습니다.
                                                    <br />- 충전구 캡을 오픈 한 후에는 운전석 문을 닫아도 됩니다.
                                                </li>
                                                <li>충전기를 연결하고 충전이 시작되면 계기판에 충전이 되고 있다는 표시가 뜨게 됩니다.</li>
                                                <li>자세한 사항은 캠핑카 사용매뉴얼 “자동차 충전 방법”을 참고해 주세요.</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="10" className="accordion-item">
                                        <Accordion.Header>충전기 커넥터가 빠지지 않을 때 어떻게 해야하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    전기차 충전시 충전기 커넥터가 빠지지 않는 상황이 발생할 수 있습니다.
                                                    <br />타 브랜드 전기차에서도 가끔 발생할 수 있는 부분이니 당황하지 마시고 아래 조치를 취해 주세요.
                                                </li>
                                                <li>
                                                    충전 커넥터 꺾임 확인
                                                    <br />- 케이블 장력에 의해 커넥터가 꺾인 경우 차량 접속구와 충전 커넥터가 일직선이 되도록 위치
                                                    시킨 후 커넥터 상단 해제 버튼을 누르고 당기세요.
                                                </li>
                                                <li>
                                                    충전기 해제 장치 이용
                                                    <br />
                                                    1. 충전기 해제 장치의 빨간색 케이블 부위를 잡아주세요. (운전석 안전벨트 하단부 옆에 위치)
                                                    <br />
                                                    2. 다른 한 손으로 충전기 해제 장치 끝 고리 부위를 잡고 당겨 주세요.
                                                    <br />
                                                    3. 커넥터 해제를 시도해 주세요.
                                                </li>
                                                <li>
                                                    차량 리모컨 키 이용
                                                    <br />- 차량 리모컨 키의 문 열림 버튼을 연속 3~4회 반복하여 누른 후 커넥터 상단 해제 버튼을 누르고
                                                    당기세요.
                                                </li>
                                                <li>
                                                    차량 충전 재시도 1
                                                    <br />- 처음부터 다시 충전을 시작 후 즉시 정상 종료하고 커넥터 상단 해제 버튼을 누르고 당기세요.
                                                </li>
                                                <li>
                                                    차량 충전 재시도 2
                                                    <br />
                                                    1. 커넥터 상단 해제 버튼을 누른 상태로 차량의 메인 전원을 OFF 해 주세요.
                                                    <br />
                                                    2. 5~10초 정도 기다린 후 메인 전원을 다시 ON 합니다.
                                                    <br />
                                                    3. 충전기에서 500원 또는 1000원을 결제하여 충전을 한 후 충전기가 초기화 되면 커넥터 상단 해제
                                                    버튼을 누르고 당기세요.
                                                </li>
                                                <li>
                                                    충전기 비상정지 버튼 이용
                                                    <br />- 충전기 비상정지 버튼을 누르고 1~2초 후 오른쪽으로 돌려 해제하고 커넥터 상단 해제 버튼을
                                                    누르고 당기세요.
                                                </li>
                                                <li>
                                                    충전기 초기화
                                                    <br />- 충전기 A/S센터에 연락하여 충전기 초기화 후 해제를 시도해 주세요.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="11" className="accordion-item">
                                        <Accordion.Header>브레이크가 밀리는 느낌이 있는데 어떻게 해야 하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    차량의 무게로 인하여 브레이크가 밀리는 느낌이 다소 있을 수 있습니다.
                                                    <br />
                                                    운행 시 앞차와 안전거리를 충분히 확보하여 운행해 주세요.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="12" className="accordion-item">
                                        <Accordion.Header>경사진 곳 정차 후 출발 시 브레이크가 밀리는데 어떻게 해야 하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    경사진 곳에 차량 정차 후 출발 시 브레이크를 천천히 떼면 차량이 뒤로 밀리니, 브레이크는 빨리 떼고
                                                    악셀을 밟아서 출발하면 됩니다.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="13" className="accordion-item">
                                        <Accordion.Header>경사진 곳에서 주차할 경우 주의해야 할 점이 있나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    경사진 곳에 차량을 주차할 경우 반드시 비치되어 있는 고임목을 이용하여 차량이 미끄러지지 않게 해야
                                                    됩니다.
                                                    <br />- 고임목은 경사 방향의 앞쪽의 타이어에 밀착하여 앞/뒤 각각 설치를 해야 합니다.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="14" className="accordion-item">
                                        <Accordion.Header>차량 정보 단말기가 동작하지 않는데 어떻게 해야하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    고객센터로 문의해 주세요.
                                                    <br />
                                                    070-7707-1768 (평일 09:00~17:00, 휴게시간 12:00~13:00)
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="15" className="accordion-item">
                                        <Accordion.Header>차량 정보 단말기의 정보가 맞지않는데 어떻게 해야 하나요?</Accordion.Header>

                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    고객센터로 문의해 주세요.
                                                    <br />
                                                    070-7707-1768 (평일 09:00~17:00, 휴게시간 12:00~13:00)
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </>
                            )}
                        </Accordion>
                    </section>
                </Col>
            </div>
        </>
    );
}

export default Faq;
