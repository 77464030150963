import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Space } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken, utilAxiosWithAuth } from "../../utils/customAxios";
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { useAuthState } from "../../provider/AuthProvider";

import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as TypeUtils from "../../commons/typeUtils";
import * as Utils from "../../utils/utils";

import moment from "moment";
import SelectRegion from "./SelectRegion";
import styles from "./Search.module.css";
import CustomRangePicker from "./CustomRangePicker";
import IconCar from "../../assets/images/home/rent/icon_car.png";
import IconTag from "../../assets/images/home/rent/icon_tag.png";
import IconItem from "../../assets/images/home/rent/icon_item.png";

const ReservationInfo = ({
    textType,
    value,
    disabledRanges,
    rentPeriodLimits,
    rentPeakSeasons,
    onChange,
}: {
    textType?: boolean;
    value?: TypeUtils.ReservationType;
    disabledRanges?: Array<TypeUtils.DateType>;
    rentPeriodLimits?: Array<TypeDTO.RentPeriodLimitDto>;
    rentPeakSeasons?: Array<TypeDTO.RentPeakSeasonDto>;
    onChange?: (type: string, value: any) => void;
}) => {
    const [form] = Form.useForm();
    const location = useLocation();
    const userDetails = useAuthState();
    const { Option } = Select;

    const [disabled, setDisabled] = useState<boolean>(false);
    const [extraTimes, setExtraTimes] = useState<Array<TypeDTO.RentExtraTimeDto>>();
    const [rentPaidItems, setRentPaidItems] = useState<Array<TypeDTO.RentPaidItemDto>>();
    const [selectedRentPaidItems, setSelectedRentPaidItems] = useState<Array<TypeDTO.RentPaidItemDto>>([]);
    const [insurances, setInsurances] = useState<Array<TypeDTO.RentInsuranceDto>>();
    const [coupons, setCoupons] = useState<Array<TypeDTO.UserCouponDto>>();
    const [userCoupons, setUserCoupons] = useState<Array<TypeDTO.UserCouponDto>>();
    const [selectedUserCoupon, setSelectedUserCoupon] = useState<TypeDTO.UserCouponDto>();
    const [multiCoupons, setMultiCoupons] = useState<Array<TypeDTO.UserCouponDto>>();
    const [selectedMultiCoupon, setSelectedMultiCoupon] = useState<TypeDTO.UserCouponDto>();
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>();
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>();
    const [tableChairDisabled, setTableChairDisabled] = useState<boolean>(false);
    const [beddingSetDisabled, setBeddingSetDisabled] = useState<boolean>(false);
    const [userCouponMessage, setUserCouponMessage] = useState<string>();
    const [multiCouponMessage, setMultiCouponMessage] = useState<string>();

    useEffect(() => {
        setDisabled(Common.PAGE_RENT_RESERVATION.includes(location.pathname));
    }, [location]);

    useEffect(() => {
        setSelectedStartDate(value?.startDate && value?.startDate !== "null" ? new Date(value.startDate) : null);
        setSelectedEndDate(value?.endDate && value?.endDate !== "null" ? new Date(value.endDate) : null);

        requestGetExtraTimes();
        requestGetInsurances();
        requestGetRentPaidItems();

        userDetails?.user && requestGetCoupons();
    }, []);

    //extraTimes
    const requestAxiosGetExtraTimes = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_EXTRA_TIMES_URL);
        return response.data;
    };

    const {
        loading: loadingGetExtraTimes,
        error: errorGetExtraTimes,
        data: resultGetExtraTimes,
        execute: requestGetExtraTimes,
    } = useAsyncAxios(requestAxiosGetExtraTimes);

    useEffect(() => {
        if (!resultGetExtraTimes) return;
        console.log("resultGetExtraTimes", resultGetExtraTimes);

        setExtraTimes(resultGetExtraTimes.rentExtraTimes);
    }, [resultGetExtraTimes]);

    useEffect(() => {
        if (!errorGetExtraTimes) return;

        console.log("errorGetExtraTimes", errorGetExtraTimes);
    }, [errorGetExtraTimes]);

    //rentPaidItems
    const requestAxiosGetRentPaidItems = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PAID_ITEMS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRentPaidItems,
        error: errorGetRentPaidItems,
        data: resultGetRentPaidItems,
        execute: requestGetRentPaidItems,
    } = useAsyncAxios(requestAxiosGetRentPaidItems);

    useEffect(() => {
        if (!resultGetRentPaidItems) return;
        console.log("resultGetRentPaidItems", resultGetRentPaidItems);

        setRentPaidItems(resultGetRentPaidItems.rentPaidItems);
    }, [resultGetRentPaidItems]);

    useEffect(() => {
        if (!errorGetRentPaidItems) return;
        console.log("errorGetRentPaidItems", errorGetRentPaidItems);
    }, [errorGetRentPaidItems]);

    //insurances
    const requestAxiosGetInsurances = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_INSURANCES_URL);
        return response.data;
    };

    const {
        loading: loadingGetInsurances,
        error: errorGetInsurances,
        data: resultGetInsurances,
        execute: requestGetInsurances,
    } = useAsyncAxios(requestAxiosGetInsurances);

    useEffect(() => {
        if (!resultGetInsurances) return;
        console.log("resultGetInsurances", resultGetInsurances);

        setInsurances(resultGetInsurances.rentInsurances);
        onChangeEvent("insurance", resultGetInsurances.rentInsurances[0]);
    }, [resultGetInsurances]);

    useEffect(() => {
        if (!errorGetInsurances) return;

        console.log("errorGetInsurances", errorGetInsurances);
    }, [errorGetInsurances]);

    //coupon
    const requestAxiosGetCoupons = async () => {
        const response = await utilAxiosWithAuth().get(Request.MY_COUPON_URL);
        return response.data;
    };

    const {
        loading: loadingGetCoupons,
        error: errorGetCoupons,
        data: resultGetCoupons,
        execute: requestGetCoupons,
    } = useAsyncAxios(requestAxiosGetCoupons);

    useEffect(() => {
        if (!resultGetCoupons) return;

        setCoupons(
            resultGetCoupons.userCoupons.filter(
                (item: TypeDTO.UserCouponDto) =>
                    item.coupon.couponCoverage === "RENT" &&
                    !item.usedDate &&
                    new Date(item.expiredDate ?? item.coupon.expiredDate) >= new Date() &&
                    new Date(item.effectiveDate ?? item.coupon.effectiveDate) < new Date()
            )
        );
    }, [resultGetCoupons]);

    useEffect(() => {
        if (!errorGetCoupons) return;

        console.log("errorGetCoupons", errorGetCoupons);
    }, [errorGetCoupons]);

    useEffect(() => {
        if (!coupons) return;

        setUserCouponMessage(undefined);
        setMultiCouponMessage(undefined);

        if (selectedUserCoupon && value?.rawTotalPrice !== undefined && value?.price !== undefined && value?.userCouponDiscounted !== undefined) {
            if (value.rawTotalPrice < 0 && value?.price <= value?.userCouponDiscounted && !selectedUserCoupon.coupon.rentPaidItemId) {
                setUserCouponMessage("할인 금액이 결제 금액을 초과합니다.\n쿠폰 사용은 가능하지만 차액금은 환불되지 않습니다.");
                if (selectedMultiCoupon && !selectedMultiCoupon.coupon.rentPaidItemId) {
                    setSelectedMultiCoupon(undefined);
                    onChangeEvent("multiCoupon", undefined);
                }
            }
        }
        if (selectedMultiCoupon && value?.rawTotalPrice !== undefined) {
            if (value.rawTotalPrice < 0 && !selectedMultiCoupon.coupon.rentPaidItemId) {
                setMultiCouponMessage("할인 금액이 결제 금액을 초과합니다.\n쿠폰 사용은 가능하지만 차액금은 환불되지 않습니다.");
            }
        }

        if (!selectedUserCoupon && selectedMultiCoupon) {
            setSelectedMultiCoupon(undefined);
            onChangeEvent("multiCoupon", undefined);
        }

        if (!selectedStartDate || !selectedEndDate) {
            if (selectedRentPaidItems && selectedRentPaidItems.length > 0) {
                setSelectedRentPaidItems([]);
                onChangeEvent("rentPaidItems", []);
            }
            if (selectedUserCoupon) {
                setSelectedUserCoupon(undefined);
                onChangeEvent("coupon", undefined);
            }
            if (selectedMultiCoupon) {
                setSelectedMultiCoupon(undefined);
                onChangeEvent("multiCoupon", undefined);
            }
        }

        let userCouponRentPaidItemIds: number[] | undefined = [];
        let multiCouponRentPaidItemIds: number[] | undefined = [];
        let tableChairItem: TypeDTO.RentPaidItemDto | undefined;
        let beddingSetItem: TypeDTO.RentPaidItemDto | undefined;

        if (selectedUserCoupon?.coupon.rentPaidItemId) {
            const rentPaidItem = onChangeRentPaidItem(selectedUserCoupon?.coupon.rentPaidItemId);

            if (rentPaidItem) {
                userCouponRentPaidItemIds = rentPaidItems
                    ?.filter((item) => item.rentPaidItemType === rentPaidItem?.rentPaidItemType)
                    .map((item) => item.rentPaidItemId);

                tableChairItem = rentPaidItem.rentPaidItemType === "TABLE_CHAIR" ? rentPaidItem : undefined;
                beddingSetItem = rentPaidItem.rentPaidItemType === "BEDDING_SET" ? rentPaidItem : undefined;
            } else {
                invalidRentPaidItemCoupon();
            }
        }

        if (selectedMultiCoupon?.coupon.rentPaidItemId) {
            const rentPaidItem = onChangeRentPaidItem(selectedMultiCoupon?.coupon.rentPaidItemId);

            if (rentPaidItem) {
                multiCouponRentPaidItemIds = rentPaidItems
                    ?.filter((item) => item.rentPaidItemType === rentPaidItem?.rentPaidItemType)
                    .map((item) => item.rentPaidItemId);

                tableChairItem = rentPaidItem?.rentPaidItemType === "TABLE_CHAIR" ? rentPaidItem : tableChairItem;
                beddingSetItem = rentPaidItem?.rentPaidItemType === "BEDDING_SET" ? rentPaidItem : beddingSetItem;
            } else {
                invalidRentPaidItemCoupon();
            }
        }

        if (tableChairDisabled && tableChairItem === undefined) {
            onChangeRentPaidItem(0, "TABLE_CHAIR");
        }
        if (beddingSetDisabled && beddingSetItem === undefined) {
            onChangeRentPaidItem(0, "BEDDING_SET");
        }
        setTableChairDisabled(tableChairItem !== undefined);
        setBeddingSetDisabled(beddingSetItem !== undefined);

        let onlyWeekdays = false;
        let onlyWeekends = false;

        if (selectedStartDate && selectedEndDate) {
            const { weekdays, weekends } = Utils.countDays(selectedStartDate, selectedEndDate, rentPeakSeasons);
            if (weekdays > 0 && weekends === 0) {
                onlyWeekdays = true;
            }
            if (weekends > 0 && weekdays === 0) {
                onlyWeekends = true;
            }
        }

        setUserCoupons(
            coupons
                ?.map((item) => ({
                    ...item,
                    disabled:
                        (onlyWeekdays && !item.coupon.weekdayAvailable) ||
                        (onlyWeekends && !item.coupon.weekendAvailable) ||
                        (selectedMultiCoupon && selectedMultiCoupon.userCouponId === item.userCouponId) ||
                        !!multiCouponRentPaidItemIds?.includes(item.coupon.rentPaidItemId ?? -1),
                }))
                .sort((a, b) => {
                    if (a.disabled !== b.disabled) return a.disabled ? 1 : -1;
                    if (a.coupon.multiCoupon !== b.coupon.multiCoupon) return a.coupon.multiCoupon ? 1 : -1;

                    return 0;
                })
        );

        setMultiCoupons(
            coupons
                ?.filter((coupon) => coupon.coupon.multiCoupon)
                .map((item) => ({
                    ...item,
                    disabled:
                        !item.coupon.multiCoupon ||
                        (onlyWeekdays && !item.coupon.weekdayAvailable) ||
                        (onlyWeekends && !item.coupon.weekendAvailable) ||
                        (selectedUserCoupon && selectedUserCoupon.userCouponId === item.userCouponId) ||
                        (!item.coupon.rentPaidItemId && (value?.rawTotalPrice ?? 0) < item.coupon.discountPrice) ||
                        !!userCouponRentPaidItemIds?.includes(item.coupon.rentPaidItemId ?? -1),
                }))
                .sort((a, b) => {
                    if (a.disabled !== b.disabled) return a.disabled ? 1 : -1;
                    if (a.coupon.multiCoupon !== b.coupon.multiCoupon) return a.coupon.multiCoupon ? -1 : 1;

                    return 0;
                })
        );
    }, [
        coupons,
        selectedStartDate,
        selectedEndDate,
        selectedUserCoupon,
        selectedMultiCoupon,
        value?.price,
        value?.rawTotalPrice,
        value?.userCouponDiscounted,
        rentPeakSeasons,
    ]);

    const onChangeEvent = (type: keyof TypeUtils.ReservationType, value: any) => {
        onChange && onChange(type, value);
    };

    const onChangeRentPaidItem = (rentPaidItemId: number, rentPaidItemType?: "TABLE_CHAIR" | "BEDDING_SET") => {
        const rentPaidItem = rentPaidItems?.find((item) => item.rentPaidItemId === rentPaidItemId);

        if (!rentPaidItemType) {
            rentPaidItemType = rentPaidItem?.rentPaidItemType;
        }

        const nextSelectedItems = selectedRentPaidItems.filter((item) => item.rentPaidItemType !== rentPaidItemType);

        if (rentPaidItem) {
            if (rentPaidItemType === "TABLE_CHAIR") {
                nextSelectedItems.unshift(rentPaidItem);
            } else {
                nextSelectedItems.push(rentPaidItem);
            }
        }

        setSelectedRentPaidItems(nextSelectedItems);
        onChangeEvent("rentPaidItems", nextSelectedItems);

        return rentPaidItem;
    };

    const invalidRentPaidItemCoupon = () => {
        Modal.error({
            title: "사용 할 수 없는 쿠폰",
            content: "쿠폰에 해당하는 유료 대여 품목이 없습니다.",
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    return (
        <>
            <MobileView className="mobile">
                <h4 className="mb-2">{String.reservationInfo}</h4>
                {textType ? (
                    <>
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p>{String.region}</p>
                            <p>{value?.rentCar?.rentRegion.rentRegionName}</p>
                        </Space>
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p style={{ minWidth: "60px" }}>{String.rentalDate}</p>
                            <p style={{ textAlign: "right" }}>
                                {moment(value?.startDate).format(Common.FORMAT_DATE_WITH_DAY) +
                                    " ~ " +
                                    moment(value?.endDate).format(Common.FORMAT_DATE_WITH_DAY)}
                            </p>
                        </Space>
                        {value?.insurance?.insuranceName && (
                            <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <p>{String.addInsurance}</p>
                                <p>{value?.insurance?.insuranceName ?? String.dash}</p>
                            </Space>
                        )}
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <p>{String.paidItems}</p>
                            <p style={{ textAlign: "right" }}>
                                {value?.rentPaidItems ? (
                                    value.rentPaidItems.map((item) => (
                                        <>
                                            {item.rentPaidItemName}
                                            <br />
                                        </>
                                    ))
                                ) : (
                                    <>{String.dash}</>
                                )}
                            </p>
                        </Space>
                    </>
                ) : (
                    <Form className={`form ${styles.reservationForm}`} name="basic" initialValues={{ remember: true }} autoComplete="off" form={form}>
                        <Form.Item style={{ display: "none" }}>
                            <SelectRegion value={value?.regionId} disabled={true} />
                        </Form.Item>
                        <Space direction="vertical" size={6} style={{ width: "100%" }}>
                            <div>
                                <span className="text-danger text-bold">*</span>
                                <span className="b-md text-bold">{String.rentalDate}</span>
                            </div>
                            <Form.Item>
                                <CustomRangePicker
                                    value={[
                                        value && value?.startDate && value?.startDate !== "null" ? new Date(value.startDate) : null,
                                        value && value?.endDate && value?.endDate !== "null" ? new Date(value.endDate) : null,
                                    ]}
                                    disabledRanges={disabledRanges}
                                    rentPeriodLimits={rentPeriodLimits}
                                    rentPeakSeasons={rentPeakSeasons}
                                    onChange={(startDate, endDate) => {
                                        setSelectedStartDate(startDate);
                                        setSelectedEndDate(endDate);
                                        onChangeEvent(
                                            "startDate",
                                            startDate ? moment(startDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null
                                        );
                                        onChangeEvent("endDate", endDate ? moment(endDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null);
                                    }}
                                />
                            </Form.Item>
                        </Space>
                        {insurances && insurances.length > 0 && (
                            <Space direction="vertical" size={6} style={{ width: "100%" }}>
                                <div>
                                    <span className="text-danger text-bold">*</span>
                                    <span className="b-md text-bold">{String.insurance}</span>
                                </div>
                                <Form.Item>
                                    <Select
                                        className={`${styles.select} ${styles.selectRegion} custom-select w-100`}
                                        suffixIcon={<img src={IconCar} width={14} height={14} />}
                                        placeholder={<p style={{ marginLeft: "20px" }}>{String.addInsurance}</p>}
                                        onChange={(value) =>
                                            insurances &&
                                            onChangeEvent(
                                                "insurance",
                                                insurances?.find((item) => item.rentInsuranceId === value)
                                            )
                                        }
                                        value={value?.insurance?.rentInsuranceId}
                                        disabled={disabled || !selectedStartDate || !selectedEndDate}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                                    >
                                        {insurances?.map((item: TypeDTO.RentInsuranceDto) => (
                                            <Option key={item.rentInsuranceId} value={item.rentInsuranceId}>
                                                <span>
                                                    {item.insuranceName}({String.required})
                                                </span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Space>
                        )}
                        <Space direction="vertical" size={6} style={{ width: "100%" }}>
                            <p className="b-md text-bold">{String.paidItems}</p>
                            <Form.Item>
                                <Select
                                    className={`${styles.select} ${styles.selectRegion} custom-select w-100`}
                                    suffixIcon={<img src={IconItem} width={14} height={14} />}
                                    placeholder={<p style={{ marginLeft: "20px" }}>테이블×의자 세트 추가</p>}
                                    onChange={(value) => {
                                        if (!rentPaidItems) {
                                            return;
                                        }

                                        onChangeRentPaidItem(value, "TABLE_CHAIR");
                                    }}
                                    value={value?.rentPaidItems?.filter((item) => item.rentPaidItemType === "TABLE_CHAIR")[0]?.rentPaidItemId}
                                    disabled={disabled || tableChairDisabled || !selectedStartDate || !selectedEndDate}
                                    allowClear
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                                >
                                    {rentPaidItems
                                        ?.filter((item) => item.rentPaidItemType === "TABLE_CHAIR")
                                        ?.map((item: TypeDTO.RentPaidItemDto) => (
                                            <Option key={item.rentPaidItemId} value={item.rentPaidItemId}>
                                                <span>{item.rentPaidItemName}</span>
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Space>
                        <Form.Item>
                            <Select
                                className={`${styles.select} ${styles.selectRegion} custom-select w-100`}
                                suffixIcon={<img src={IconItem} width={14} height={14} />}
                                placeholder={<p style={{ marginLeft: "20px" }}>침구 세트 추가</p>}
                                onChange={(value) => {
                                    if (!rentPaidItems) {
                                        return;
                                    }

                                    onChangeRentPaidItem(value, "BEDDING_SET");
                                }}
                                value={value?.rentPaidItems?.filter((item) => item.rentPaidItemType === "BEDDING_SET")[0]?.rentPaidItemId}
                                disabled={disabled || beddingSetDisabled || !selectedStartDate || !selectedEndDate}
                                allowClear
                                getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                            >
                                {rentPaidItems
                                    ?.filter((item) => item.rentPaidItemType === "BEDDING_SET")
                                    ?.map((item: TypeDTO.RentPaidItemDto) => (
                                        <Option key={item.rentPaidItemId} value={item.rentPaidItemId}>
                                            <span>{item.rentPaidItemName}</span>
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Space direction="vertical" size={6} style={{ width: "100%" }}>
                            <p className="b-md text-bold">{String.coupon}</p>
                            <Form.Item className={`${userCouponMessage ? "mb-0" : ""}`}>
                                <Select
                                    className={`${styles.select} ${styles.selectRegion} custom-select w-100`}
                                    suffixIcon={<img src={IconTag} width={14} height={14} />}
                                    placeholder={
                                        <p style={{ marginLeft: "20px" }}>
                                            {userDetails?.user ? `${String.coupon} 선택` : "쿠폰은 로그인 후 적용하실 수 있습니다."}
                                        </p>
                                    }
                                    onChange={(value) => {
                                        if (!userCoupons) {
                                            return;
                                        }

                                        const coupon = userCoupons?.find((item) => item.userCouponId === value);

                                        setSelectedUserCoupon(coupon);
                                        onChangeEvent("coupon", coupon);
                                    }}
                                    value={value?.coupon?.userCouponId}
                                    allowClear
                                    disabled={disabled || !userDetails?.user || !selectedStartDate || !selectedEndDate}
                                    notFoundContent={
                                        <Space direction="vertical" className="notFoundContent">
                                            <img src={IconTag} />
                                            {String.msg_noCoupon}
                                        </Space>
                                    }
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                                >
                                    {userCoupons?.map((item: TypeDTO.UserCouponDto) => (
                                        <Option key={item.userCouponId} value={item.userCouponId} disabled={item.disabled}>
                                            <span>{item.coupon.couponName}</span>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Space>
                        {userCouponMessage && (
                            <div className="text-danger mb-2" style={{ whiteSpace: "pre-line" }}>
                                {userCouponMessage}
                            </div>
                        )}
                        <Space direction="vertical" size={6} style={{ width: "100%" }}>
                            <p className="b-md text-bold">{String.multiCoupon}</p>
                            <div>
                                <Form.Item className="mb-0">
                                    <Select
                                        className={`${styles.select} ${styles.selectRegion} custom-select w-100`}
                                        suffixIcon={<img src={IconTag} width={14} height={14} />}
                                        placeholder={
                                            <p style={{ marginLeft: "20px" }}>
                                                {userDetails?.user ? `${String.multiCoupon} 선택` : "쿠폰은 로그인 후 적용하실 수 있습니다."}
                                            </p>
                                        }
                                        onChange={(value) => {
                                            if (!multiCoupons) {
                                                return;
                                            }
                                            const coupon = multiCoupons?.find((item) => item.userCouponId === value);
                                            setSelectedMultiCoupon(coupon);
                                            onChangeEvent("multiCoupon", coupon);
                                        }}
                                        value={value?.multiCoupon?.userCouponId}
                                        allowClear
                                        disabled={disabled || !userDetails?.user || !selectedStartDate || !selectedEndDate || !selectedUserCoupon}
                                        notFoundContent={
                                            <Space direction="vertical" className="notFoundContent">
                                                <img src={IconTag} />
                                                {String.msg_noCoupon}
                                            </Space>
                                        }
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                                    >
                                        {multiCoupons?.map((item: TypeDTO.UserCouponDto) => (
                                            <Option key={item.userCouponId} value={item.userCouponId} disabled={item.disabled}>
                                                <span>{item.coupon.couponName}</span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {multiCouponMessage && (
                                    <div className="text-danger" style={{ whiteSpace: "pre-line" }}>
                                        {multiCouponMessage}
                                    </div>
                                )}
                            </div>
                        </Space>
                    </Form>
                )}
            </MobileView>

            <BrowserView className="browser">
                {textType ? (
                    <>
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p>{String.region}</p>
                            <p>{value?.rentCar?.rentRegion.rentRegionName}</p>
                        </Space>
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p>{String.rentalDate}</p>
                            <p>
                                {moment(value?.startDate).format(Common.FORMAT_DATE_WITH_DAY) +
                                    " ~ " +
                                    moment(value?.endDate).format(Common.FORMAT_DATE_WITH_DAY)}
                            </p>
                        </Space>
                        {value?.insurance?.insuranceName && (
                            <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <p>{String.addInsurance}</p>
                                <p>{value?.insurance?.insuranceName ?? String.dash}</p>
                            </Space>
                        )}
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <p>{String.paidItems}</p>
                            <p style={{ textAlign: "right" }}>
                                {value?.rentPaidItems ? (
                                    value.rentPaidItems.map((item) => (
                                        <>
                                            {item.rentPaidItemName}
                                            <br />
                                        </>
                                    ))
                                ) : (
                                    <>{String.dash}</>
                                )}
                            </p>
                        </Space>
                    </>
                ) : (
                    <Form
                        className={styles.reservationForm}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        form={form}
                    >
                        <span className="text-danger text-bold">*</span>
                        <span className="b-md text-bold">{String.rentalDate}</span>
                        <CustomRangePicker
                            className="mb-3 mt-2"
                            value={[
                                value && value?.startDate && value?.startDate !== "null" ? new Date(value.startDate) : null,
                                value && value?.endDate && value?.endDate !== "null" ? new Date(value.endDate) : null,
                            ]}
                            disabledRanges={disabledRanges}
                            rentPeriodLimits={rentPeriodLimits}
                            rentPeakSeasons={rentPeakSeasons}
                            onChange={(startDate, endDate) => {
                                setSelectedStartDate(startDate);
                                setSelectedEndDate(endDate);
                                onChangeEvent("startDate", startDate ? moment(startDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null);
                                onChangeEvent("endDate", endDate ? moment(endDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null);
                            }}
                        />
                        {insurances && insurances.length > 0 && (
                            <>
                                <span className="text-danger text-bold">*</span>
                                <span className="b-md text-bold">{String.insurance}</span>
                                <Select
                                    className={`${styles.select} ${styles.selectRegion} custom-select w-100 mb-3 mt-2`}
                                    suffixIcon={<img src={IconCar} width={14} height={14} />}
                                    placeholder={<p style={{ marginLeft: "20px" }}>{String.addInsurance}</p>}
                                    onChange={(value) =>
                                        insurances !== undefined &&
                                        onChangeEvent(
                                            "insurance",
                                            insurances?.find((item) => item.rentInsuranceId === value)
                                        )
                                    }
                                    value={value?.insurance?.rentInsuranceId}
                                    disabled={disabled || !selectedStartDate || !selectedEndDate}
                                    popupClassName={styles.browserSelectDropdown}
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                                >
                                    {insurances?.map((item: TypeDTO.RentInsuranceDto) => (
                                        <Option key={item.rentInsuranceId} value={item.rentInsuranceId}>
                                            <span>
                                                {item.insuranceName}({String.required})
                                            </span>
                                        </Option>
                                    ))}
                                </Select>
                            </>
                        )}
                        <p className="b-md text-bold">{String.paidItems}</p>
                        <Select
                            className={`${styles.select} ${styles.selectRegion} custom-select w-100 mb-2 mt-2`}
                            suffixIcon={<img src={IconItem} width={14} height={14} />}
                            placeholder={<p style={{ marginLeft: "20px" }}>테이블×의자 세트 추가</p>}
                            onChange={(value) => {
                                if (!rentPaidItems) {
                                    return;
                                }

                                onChangeRentPaidItem(value, "TABLE_CHAIR");
                            }}
                            allowClear
                            value={value?.rentPaidItems?.filter((item) => item.rentPaidItemType === "TABLE_CHAIR")[0]?.rentPaidItemId}
                            disabled={disabled || tableChairDisabled || !selectedStartDate || !selectedEndDate}
                            popupClassName={styles.browserSelectDropdown}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                        >
                            {rentPaidItems
                                ?.filter((item) => item.rentPaidItemType === "TABLE_CHAIR")
                                ?.map((item: TypeDTO.RentPaidItemDto) => (
                                    <Option key={item.rentPaidItemId} value={item.rentPaidItemId}>
                                        <span>{item.rentPaidItemName}</span>
                                    </Option>
                                ))}
                        </Select>
                        <Select
                            className={`${styles.select} ${styles.selectRegion} custom-select w-100 mb-3 mt-1`}
                            suffixIcon={<img src={IconItem} width={14} height={14} />}
                            placeholder={<p style={{ marginLeft: "20px" }}>침구 세트 추가</p>}
                            onChange={(value) => {
                                if (!rentPaidItems) {
                                    return;
                                }

                                onChangeRentPaidItem(value, "BEDDING_SET");
                            }}
                            allowClear
                            value={value?.rentPaidItems?.filter((item) => item.rentPaidItemType === "BEDDING_SET")[0]?.rentPaidItemId}
                            disabled={disabled || beddingSetDisabled || !selectedStartDate || !selectedEndDate}
                            popupClassName={styles.browserSelectDropdown}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                        >
                            {rentPaidItems
                                ?.filter((item) => item.rentPaidItemType === "BEDDING_SET")
                                ?.map((item: TypeDTO.RentPaidItemDto) => (
                                    <Option key={item.rentPaidItemId} value={item.rentPaidItemId}>
                                        <span>{item.rentPaidItemName}</span>
                                    </Option>
                                ))}
                        </Select>
                        <p className="b-md text-bold">{String.coupon}</p>
                        <Select
                            className={`${styles.select} ${styles.selectRegion} ${userCouponMessage ? "" : "mb-3"} custom-select w-100 mt-2`}
                            placeholder={
                                <p style={{ marginLeft: "20px" }}>
                                    {userDetails?.user ? `${String.coupon} 선택` : "쿠폰은 로그인 후 적용하실 수 있습니다."}
                                </p>
                            }
                            suffixIcon={<img src={IconTag} width={14} height={14} />}
                            onChange={(value) => {
                                if (!userCoupons) {
                                    return;
                                }

                                const coupon = userCoupons?.find((item) => item.userCouponId === value);

                                setSelectedUserCoupon(coupon);
                                onChangeEvent("coupon", coupon);
                            }}
                            value={value?.coupon?.userCouponId}
                            allowClear
                            disabled={disabled || !userDetails?.user || !selectedStartDate || !selectedEndDate}
                            popupClassName={styles.browserSelectDropdown}
                            notFoundContent={
                                <Space direction="vertical" className="notFoundContent">
                                    <img src={IconTag} />
                                    {String.msg_noCoupon}
                                </Space>
                            }
                            getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                        >
                            {userCoupons?.map((item: TypeDTO.UserCouponDto) => (
                                <Option key={item.userCouponId} value={item.userCouponId} disabled={item.disabled}>
                                    <span>{item.coupon.couponName}</span>
                                </Option>
                            ))}
                        </Select>
                        {userCouponMessage && (
                            <div className="text-danger mb-2" style={{ whiteSpace: "pre-line" }}>
                                {userCouponMessage}
                            </div>
                        )}
                        <>
                            <p className="b-md text-bold">{String.multiCoupon}</p>
                            <Select
                                className={`${styles.select} ${styles.selectRegion} custom-select w-100 mb-0 mt-2`}
                                placeholder={
                                    <p style={{ marginLeft: "20px" }}>
                                        {userDetails?.user ? `${String.multiCoupon} 선택` : "쿠폰은 로그인 후 적용하실 수 있습니다."}
                                    </p>
                                }
                                suffixIcon={<img src={IconTag} width={14} height={14} />}
                                onChange={(value) => {
                                    if (!multiCoupons) {
                                        return;
                                    }
                                    const coupon = multiCoupons?.find((item) => item.userCouponId === value);
                                    setSelectedMultiCoupon(coupon);
                                    onChangeEvent("multiCoupon", coupon);
                                }}
                                value={value?.multiCoupon?.userCouponId}
                                allowClear
                                disabled={disabled || !userDetails?.user || !selectedStartDate || !selectedEndDate || !selectedUserCoupon}
                                popupClassName={styles.browserSelectDropdown}
                                notFoundContent={
                                    <Space direction="vertical" className="notFoundContent">
                                        <img src={IconTag} />
                                        {String.msg_noCoupon}
                                    </Space>
                                }
                                getPopupContainer={(triggerNode) => triggerNode.parentElement.parentElement}
                            >
                                {multiCoupons?.map((item: TypeDTO.UserCouponDto) => (
                                    <Option key={item.userCouponId} value={item.userCouponId} disabled={item.disabled}>
                                        <span>{item.coupon.couponName}</span>
                                    </Option>
                                ))}
                            </Select>
                            {multiCouponMessage && (
                                <div className="text-danger" style={{ whiteSpace: "pre-line" }}>
                                    {multiCouponMessage}
                                </div>
                            )}
                        </>
                    </Form>
                )}
            </BrowserView>
        </>
    );
};

export default ReservationInfo;
