import React from "react";
import { Button, Col } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { BrowserView, MobileView } from "react-device-detect";

import * as Utils from "../../utils/utils";
import styles from "./Brochure.module.css";
import boothImage from "../../assets/images/brochure/st1/booth.png";

function Ces2025Booth() {
    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <MobileView className="mobile">
                <Button
                    className={styles.floatingButton}
                    type="primary"
                    onClick={() => Utils.sendLinkToApp("https://exhibitors.ces.tech/8_0/floorplan/?hallID=A&selectedBooth=10743")}
                >
                    <EnvironmentOutlined style={{ fontSize: 20 }} />
                </Button>
                <div className={styles.mobileLayout}>
                    <img className={styles.backgroundImage} src={boothImage} />
                </div>
            </MobileView>

            <BrowserView className="browser">
                <Button
                    className={styles.floatingButton}
                    type="primary"
                    onClick={() => Utils.sendLinkToApp("https://exhibitors.ces.tech/8_0/floorplan/?hallID=A&selectedBooth=10743")}
                >
                    <EnvironmentOutlined style={{ fontSize: 20 }} />
                </Button>
                <Col xs={{ span: 24 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className={styles.browserLayout}>
                    <img className={styles.backgroundImage} src={boothImage} />
                </Col>
            </BrowserView>
        </div>
    );
}
export default Ces2025Booth;
